/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GatewayMeasureSite
 */
export interface GatewayMeasureSite {
  /**
   *
   * @type {string}
   * @memberof GatewayMeasureSite
   */
  macAddress?: string;
  /**
   *
   * @type {number}
   * @memberof GatewayMeasureSite
   */
  modbusAddress?: number;
  /**
   *
   * @type {string}
   * @memberof GatewayMeasureSite
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayMeasureSite
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayMeasureSite
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayMeasureSite
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the GatewayMeasureSite interface.
 */
export function instanceOfGatewayMeasureSite(value: object): value is GatewayMeasureSite {
  return true;
}

export function GatewayMeasureSiteFromJSON(json: any): GatewayMeasureSite {
  return GatewayMeasureSiteFromJSONTyped(json, false);
}

export function GatewayMeasureSiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayMeasureSite {
  if (json == null) {
    return json;
  }
  return {
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    modbusAddress: json['modbusAddress'] == null ? undefined : json['modbusAddress'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function GatewayMeasureSiteToJSON(json: any): GatewayMeasureSite {
  return GatewayMeasureSiteToJSONTyped(json, false);
}

export function GatewayMeasureSiteToJSONTyped(
  value?: GatewayMeasureSite | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    macAddress: value['macAddress'],
    modbusAddress: value['modbusAddress'],
    model: value['model'],
    name: value['name'],
    serialNumber: value['serialNumber'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
