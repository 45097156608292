/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ApplianceResponse,
  ApplianceState,
  AppliancesResponse,
  GatewayMeasureSiteResponse,
  MeasureSite,
  SmartModuleApplianceData,
  SmartModuleState
} from '../models/index';
import {
  ApplianceResponseFromJSON,
  ApplianceResponseToJSON,
  ApplianceStateFromJSON,
  ApplianceStateToJSON,
  AppliancesResponseFromJSON,
  AppliancesResponseToJSON,
  GatewayMeasureSiteResponseFromJSON,
  GatewayMeasureSiteResponseToJSON,
  MeasureSiteFromJSON,
  MeasureSiteToJSON,
  SmartModuleApplianceDataFromJSON,
  SmartModuleApplianceDataToJSON,
  SmartModuleStateFromJSON,
  SmartModuleStateToJSON
} from '../models/index';

export interface GetApplianceDataUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetApplianceDataWithSerialNumberUsingGETRequest {
  serialNumber: string;
}

export interface GetApplianceStateUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetMeasureSiteDataUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetMeasureSitesOfGatewayUsingGETRequest {
  gatewayId: string;
}

export interface GetSmartModuleDataWithSerialNumberUsingGETRequest {
  serialNumber: string;
}

export interface GetZoneStateUsingGETRequest {
  virtualFridgeId: string;
}

/**
 * ApplianceControllerApi - interface
 *
 * @export
 * @interface ApplianceControllerApiInterface
 */
export interface ApplianceControllerApiInterface {
  /**
   * getApplianceData
   * @summary Gets information about appliance.
   * @param {string} virtualFridgeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getApplianceDataUsingGETRaw(
    requestParameters: GetApplianceDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApplianceResponse>>;

  /**
   * getApplianceData
   * Gets information about appliance.
   */
  getApplianceDataUsingGET(
    requestParameters: GetApplianceDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApplianceResponse>;

  /**
   * getApplianceDataWithSerialNumber
   * @summary Gets appliance data with serial number
   * @param {string} serialNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getApplianceDataWithSerialNumberUsingGETRaw(
    requestParameters: GetApplianceDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppliancesResponse>>;

  /**
   * getApplianceDataWithSerialNumber
   * Gets appliance data with serial number
   */
  getApplianceDataWithSerialNumberUsingGET(
    requestParameters: GetApplianceDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppliancesResponse>;

  /**
   * getApplianceState
   * @summary Gets appliance state by fridge ID.
   * @param {string} virtualFridgeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getApplianceStateUsingGETRaw(
    requestParameters: GetApplianceStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApplianceState>>;

  /**
   * getApplianceState
   * Gets appliance state by fridge ID.
   */
  getApplianceStateUsingGET(
    requestParameters: GetApplianceStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApplianceState>;

  /**
   * getMeasureSiteData
   * @summary Gets information about measure site.
   * @param {string} virtualFridgeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getMeasureSiteDataUsingGETRaw(
    requestParameters: GetMeasureSiteDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MeasureSite>>;

  /**
   * getMeasureSiteData
   * Gets information about measure site.
   */
  getMeasureSiteDataUsingGET(
    requestParameters: GetMeasureSiteDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MeasureSite>;

  /**
   * getMeasureSitesOfGateway
   * @summary Gets modbus and binder appliances attached to a gateway.
   * @param {string} gatewayId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getMeasureSitesOfGatewayUsingGETRaw(
    requestParameters: GetMeasureSitesOfGatewayUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GatewayMeasureSiteResponse>>;

  /**
   * getMeasureSitesOfGateway
   * Gets modbus and binder appliances attached to a gateway.
   */
  getMeasureSitesOfGatewayUsingGET(
    requestParameters: GetMeasureSitesOfGatewayUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GatewayMeasureSiteResponse>;

  /**
   * getSmartModuleDataWithSerialNumber
   * @summary Gets smartmodule fridge data with serial number
   * @param {string} serialNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getSmartModuleDataWithSerialNumberUsingGETRaw(
    requestParameters: GetSmartModuleDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SmartModuleApplianceData>>;

  /**
   * getSmartModuleDataWithSerialNumber
   * Gets smartmodule fridge data with serial number
   */
  getSmartModuleDataWithSerialNumberUsingGET(
    requestParameters: GetSmartModuleDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SmartModuleApplianceData>;

  /**
   * getZoneState
   * @summary Gets detailed zone state information.
   * @param {string} virtualFridgeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplianceControllerApiInterface
   */
  getZoneStateUsingGETRaw(
    requestParameters: GetZoneStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SmartModuleState>>;

  /**
   * getZoneState
   * Gets detailed zone state information.
   */
  getZoneStateUsingGET(
    requestParameters: GetZoneStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SmartModuleState>;
}

/**
 *
 */
export class ApplianceControllerApi extends runtime.BaseAPI implements ApplianceControllerApiInterface {
  /**
   * getApplianceData
   * Gets information about appliance.
   */
  async getApplianceDataUsingGETRaw(
    requestParameters: GetApplianceDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApplianceResponse>> {
    if (requestParameters['virtualFridgeId'] == null) {
      throw new runtime.RequiredError(
        'virtualFridgeId',
        'Required parameter "virtualFridgeId" was null or undefined when calling getApplianceDataUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['virtualFridgeId'] != null) {
      queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/appliance/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ApplianceResponseFromJSON(jsonValue));
  }

  /**
   * getApplianceData
   * Gets information about appliance.
   */
  async getApplianceDataUsingGET(
    requestParameters: GetApplianceDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApplianceResponse> {
    const response = await this.getApplianceDataUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getApplianceDataWithSerialNumber
   * Gets appliance data with serial number
   */
  async getApplianceDataWithSerialNumberUsingGETRaw(
    requestParameters: GetApplianceDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppliancesResponse>> {
    if (requestParameters['serialNumber'] == null) {
      throw new runtime.RequiredError(
        'serialNumber',
        'Required parameter "serialNumber" was null or undefined when calling getApplianceDataWithSerialNumberUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['serialNumber'] != null) {
      queryParameters['serialNumber'] = requestParameters['serialNumber'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/appliances/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => AppliancesResponseFromJSON(jsonValue));
  }

  /**
   * getApplianceDataWithSerialNumber
   * Gets appliance data with serial number
   */
  async getApplianceDataWithSerialNumberUsingGET(
    requestParameters: GetApplianceDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppliancesResponse> {
    const response = await this.getApplianceDataWithSerialNumberUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getApplianceState
   * Gets appliance state by fridge ID.
   */
  async getApplianceStateUsingGETRaw(
    requestParameters: GetApplianceStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApplianceState>> {
    if (requestParameters['virtualFridgeId'] == null) {
      throw new runtime.RequiredError(
        'virtualFridgeId',
        'Required parameter "virtualFridgeId" was null or undefined when calling getApplianceStateUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['virtualFridgeId'] != null) {
      queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/appliance/appliancestate`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ApplianceStateFromJSON(jsonValue));
  }

  /**
   * getApplianceState
   * Gets appliance state by fridge ID.
   */
  async getApplianceStateUsingGET(
    requestParameters: GetApplianceStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApplianceState> {
    const response = await this.getApplianceStateUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getMeasureSiteData
   * Gets information about measure site.
   */
  async getMeasureSiteDataUsingGETRaw(
    requestParameters: GetMeasureSiteDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MeasureSite>> {
    if (requestParameters['virtualFridgeId'] == null) {
      throw new runtime.RequiredError(
        'virtualFridgeId',
        'Required parameter "virtualFridgeId" was null or undefined when calling getMeasureSiteDataUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['virtualFridgeId'] != null) {
      queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/measuresite/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MeasureSiteFromJSON(jsonValue));
  }

  /**
   * getMeasureSiteData
   * Gets information about measure site.
   */
  async getMeasureSiteDataUsingGET(
    requestParameters: GetMeasureSiteDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MeasureSite> {
    const response = await this.getMeasureSiteDataUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getMeasureSitesOfGateway
   * Gets modbus and binder appliances attached to a gateway.
   */
  async getMeasureSitesOfGatewayUsingGETRaw(
    requestParameters: GetMeasureSitesOfGatewayUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GatewayMeasureSiteResponse>> {
    if (requestParameters['gatewayId'] == null) {
      throw new runtime.RequiredError(
        'gatewayId',
        'Required parameter "gatewayId" was null or undefined when calling getMeasureSitesOfGatewayUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/connectables/{gatewayId}`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(requestParameters['gatewayId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => GatewayMeasureSiteResponseFromJSON(jsonValue));
  }

  /**
   * getMeasureSitesOfGateway
   * Gets modbus and binder appliances attached to a gateway.
   */
  async getMeasureSitesOfGatewayUsingGET(
    requestParameters: GetMeasureSitesOfGatewayUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GatewayMeasureSiteResponse> {
    const response = await this.getMeasureSitesOfGatewayUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getSmartModuleDataWithSerialNumber
   * Gets smartmodule fridge data with serial number
   */
  async getSmartModuleDataWithSerialNumberUsingGETRaw(
    requestParameters: GetSmartModuleDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SmartModuleApplianceData>> {
    if (requestParameters['serialNumber'] == null) {
      throw new runtime.RequiredError(
        'serialNumber',
        'Required parameter "serialNumber" was null or undefined when calling getSmartModuleDataWithSerialNumberUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['serialNumber'] != null) {
      queryParameters['serialNumber'] = requestParameters['serialNumber'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/smartmodule/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => SmartModuleApplianceDataFromJSON(jsonValue));
  }

  /**
   * getSmartModuleDataWithSerialNumber
   * Gets smartmodule fridge data with serial number
   */
  async getSmartModuleDataWithSerialNumberUsingGET(
    requestParameters: GetSmartModuleDataWithSerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SmartModuleApplianceData> {
    const response = await this.getSmartModuleDataWithSerialNumberUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getZoneState
   * Gets detailed zone state information.
   */
  async getZoneStateUsingGETRaw(
    requestParameters: GetZoneStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SmartModuleState>> {
    if (requestParameters['virtualFridgeId'] == null) {
      throw new runtime.RequiredError(
        'virtualFridgeId',
        'Required parameter "virtualFridgeId" was null or undefined when calling getZoneStateUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/smartmodule/zonestate/{virtualFridgeId}`.replace(
          `{${'virtualFridgeId'}}`,
          encodeURIComponent(String(requestParameters['virtualFridgeId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => SmartModuleStateFromJSON(jsonValue));
  }

  /**
   * getZoneState
   * Gets detailed zone state information.
   */
  async getZoneStateUsingGET(
    requestParameters: GetZoneStateUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SmartModuleState> {
    const response = await this.getZoneStateUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
