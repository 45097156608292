/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationAppliance } from './OrganizationAppliance';
import {
  OrganizationApplianceFromJSON,
  OrganizationApplianceFromJSONTyped,
  OrganizationApplianceToJSON,
  OrganizationApplianceToJSONTyped
} from './OrganizationAppliance';

/**
 *
 * @export
 * @interface DefaultOrganizationGroup
 */
export interface DefaultOrganizationGroup {
  /**
   *
   * @type {Array<OrganizationAppliance>}
   * @memberof DefaultOrganizationGroup
   */
  appliances?: Array<OrganizationAppliance>;
  /**
   *
   * @type {string}
   * @memberof DefaultOrganizationGroup
   */
  groupId?: string;
}

/**
 * Check if a given object implements the DefaultOrganizationGroup interface.
 */
export function instanceOfDefaultOrganizationGroup(value: object): value is DefaultOrganizationGroup {
  return true;
}

export function DefaultOrganizationGroupFromJSON(json: any): DefaultOrganizationGroup {
  return DefaultOrganizationGroupFromJSONTyped(json, false);
}

export function DefaultOrganizationGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DefaultOrganizationGroup {
  if (json == null) {
    return json;
  }
  return {
    appliances:
      json['appliances'] == null ? undefined : (json['appliances'] as Array<any>).map(OrganizationApplianceFromJSON),
    groupId: json['groupId'] == null ? undefined : json['groupId']
  };
}

export function DefaultOrganizationGroupToJSON(json: any): DefaultOrganizationGroup {
  return DefaultOrganizationGroupToJSONTyped(json, false);
}

export function DefaultOrganizationGroupToJSONTyped(
  value?: DefaultOrganizationGroup | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    appliances:
      value['appliances'] == null ? undefined : (value['appliances'] as Array<any>).map(OrganizationApplianceToJSON),
    groupId: value['groupId']
  };
}
