/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Colleague } from './Colleague';
import { ColleagueFromJSON, ColleagueFromJSONTyped, ColleagueToJSON, ColleagueToJSONTyped } from './Colleague';

/**
 *
 * @export
 * @interface ColleagueResponse
 */
export interface ColleagueResponse {
  /**
   *
   * @type {Array<Colleague>}
   * @memberof ColleagueResponse
   */
  colleagues?: Array<Colleague>;
}

/**
 * Check if a given object implements the ColleagueResponse interface.
 */
export function instanceOfColleagueResponse(value: object): value is ColleagueResponse {
  return true;
}

export function ColleagueResponseFromJSON(json: any): ColleagueResponse {
  return ColleagueResponseFromJSONTyped(json, false);
}

export function ColleagueResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColleagueResponse {
  if (json == null) {
    return json;
  }
  return {
    colleagues: json['colleagues'] == null ? undefined : (json['colleagues'] as Array<any>).map(ColleagueFromJSON)
  };
}

export function ColleagueResponseToJSON(json: any): ColleagueResponse {
  return ColleagueResponseToJSONTyped(json, false);
}

export function ColleagueResponseToJSONTyped(
  value?: ColleagueResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    colleagues: value['colleagues'] == null ? undefined : (value['colleagues'] as Array<any>).map(ColleagueToJSON)
  };
}
