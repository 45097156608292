/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleApplianceData
 */
export interface SmartModuleApplianceData {
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  building?: string;
  /**
   *
   * @type {boolean}
   * @memberof SmartModuleApplianceData
   */
  connected?: boolean;
  /**
   *
   * @type {Date}
   * @memberof SmartModuleApplianceData
   */
  connectionStateSince?: Date;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  connectivityModule?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  countryIsoCode?: string;
  /**
   *
   * @type {Date}
   * @memberof SmartModuleApplianceData
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  department?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  floor?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  inventoryNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  measureSiteType?: SmartModuleApplianceDataMeasureSiteTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  networkType?: SmartModuleApplianceDataNetworkTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SmartModuleApplianceData
   */
  numberOfZones?: number;
  /**
   *
   * @type {Date}
   * @memberof SmartModuleApplianceData
   */
  orderDate?: Date;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  roomNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  smartModuleSoftwareVersion?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleApplianceData
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const SmartModuleApplianceDataMeasureSiteTypeEnum = {
  ROOM: 'ROOM',
  MODBUS_FRIDGE: 'MODBUS_FRIDGE',
  NOT_CONNECTABLE_FRIDGE: 'NOT_CONNECTABLE_FRIDGE',
  THIRD_PARTY_FRIDGE: 'THIRD_PARTY_FRIDGE',
  BINDER_FRIDGE: 'BINDER_FRIDGE',
  SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE'
} as const;
export type SmartModuleApplianceDataMeasureSiteTypeEnum =
  (typeof SmartModuleApplianceDataMeasureSiteTypeEnum)[keyof typeof SmartModuleApplianceDataMeasureSiteTypeEnum];

/**
 * @export
 */
export const SmartModuleApplianceDataNetworkTypeEnum = {
  LAN: 'LAN',
  WIFI: 'WIFI'
} as const;
export type SmartModuleApplianceDataNetworkTypeEnum =
  (typeof SmartModuleApplianceDataNetworkTypeEnum)[keyof typeof SmartModuleApplianceDataNetworkTypeEnum];

/**
 * Check if a given object implements the SmartModuleApplianceData interface.
 */
export function instanceOfSmartModuleApplianceData(value: object): value is SmartModuleApplianceData {
  return true;
}

export function SmartModuleApplianceDataFromJSON(json: any): SmartModuleApplianceData {
  return SmartModuleApplianceDataFromJSONTyped(json, false);
}

export function SmartModuleApplianceDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartModuleApplianceData {
  if (json == null) {
    return json;
  }
  return {
    articleNumber: json['articleNumber'] == null ? undefined : json['articleNumber'],
    building: json['building'] == null ? undefined : json['building'],
    connected: json['connected'] == null ? undefined : json['connected'],
    connectionStateSince: json['connectionStateSince'] == null ? undefined : new Date(json['connectionStateSince']),
    connectivityModule: json['connectivityModule'] == null ? undefined : json['connectivityModule'],
    countryIsoCode: json['countryIsoCode'] == null ? undefined : json['countryIsoCode'],
    created: json['created'] == null ? undefined : new Date(json['created']),
    department: json['department'] == null ? undefined : json['department'],
    floor: json['floor'] == null ? undefined : json['floor'],
    inventoryNumber: json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
    ipAddress: json['ipAddress'] == null ? undefined : json['ipAddress'],
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    measureSiteType: json['measureSiteType'] == null ? undefined : json['measureSiteType'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    networkType: json['networkType'] == null ? undefined : json['networkType'],
    numberOfZones: json['numberOfZones'] == null ? undefined : json['numberOfZones'],
    orderDate: json['orderDate'] == null ? undefined : new Date(json['orderDate']),
    organizationId: json['organizationId'] == null ? undefined : json['organizationId'],
    roomNumber: json['roomNumber'] == null ? undefined : json['roomNumber'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    smartModuleSoftwareVersion:
      json['smartModuleSoftwareVersion'] == null ? undefined : json['smartModuleSoftwareVersion'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function SmartModuleApplianceDataToJSON(json: any): SmartModuleApplianceData {
  return SmartModuleApplianceDataToJSONTyped(json, false);
}

export function SmartModuleApplianceDataToJSONTyped(
  value?: SmartModuleApplianceData | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    articleNumber: value['articleNumber'],
    building: value['building'],
    connected: value['connected'],
    connectionStateSince:
      value['connectionStateSince'] == null ? undefined : value['connectionStateSince'].toISOString(),
    connectivityModule: value['connectivityModule'],
    countryIsoCode: value['countryIsoCode'],
    created: value['created'] == null ? undefined : value['created'].toISOString(),
    department: value['department'],
    floor: value['floor'],
    inventoryNumber: value['inventoryNumber'],
    ipAddress: value['ipAddress'],
    macAddress: value['macAddress'],
    measureSiteType: value['measureSiteType'],
    model: value['model'],
    name: value['name'],
    networkType: value['networkType'],
    numberOfZones: value['numberOfZones'],
    orderDate: value['orderDate'] == null ? undefined : value['orderDate'].toISOString(),
    organizationId: value['organizationId'],
    roomNumber: value['roomNumber'],
    serialNumber: value['serialNumber'],
    smartModuleSoftwareVersion: value['smartModuleSoftwareVersion'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
