/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationsPerCountry
 */
export interface OrganizationsPerCountry {
  /**
   *
   * @type {string}
   * @memberof OrganizationsPerCountry
   */
  countryName?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationsPerCountry
   */
  organizationCount?: number;
}

/**
 * Check if a given object implements the OrganizationsPerCountry interface.
 */
export function instanceOfOrganizationsPerCountry(value: object): value is OrganizationsPerCountry {
  return true;
}

export function OrganizationsPerCountryFromJSON(json: any): OrganizationsPerCountry {
  return OrganizationsPerCountryFromJSONTyped(json, false);
}

export function OrganizationsPerCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationsPerCountry {
  if (json == null) {
    return json;
  }
  return {
    countryName: json['countryName'] == null ? undefined : json['countryName'],
    organizationCount: json['organizationCount'] == null ? undefined : json['organizationCount']
  };
}

export function OrganizationsPerCountryToJSON(json: any): OrganizationsPerCountry {
  return OrganizationsPerCountryToJSONTyped(json, false);
}

export function OrganizationsPerCountryToJSONTyped(
  value?: OrganizationsPerCountry | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    countryName: value['countryName'],
    organizationCount: value['organizationCount']
  };
}
