/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { SupportInfo } from '../models/index';
import { SupportInfoFromJSON, SupportInfoToJSON } from '../models/index';

export interface GetSupportInfoUsingGETRequest {
  countryCode: string;
}

export interface SetMaintenanceUsingPUTRequest {
  state: boolean;
}

export interface SetSupportInfoUsingPUTRequest {
  countryCode: string;
  supportInfo: SupportInfo;
}

/**
 * SupportControllerApi - interface
 *
 * @export
 * @interface SupportControllerApiInterface
 */
export interface SupportControllerApiInterface {
  /**
   * downloadApi
   * @summary Download a list of used microservice calls
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  downloadApiUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>>;

  /**
   * downloadApi
   * Download a list of used microservice calls
   */
  downloadApiUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

  /**
   * getMaintenanceState
   * @summary Gets current maintenance state.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  getMaintenanceStateUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>>;

  /**
   * getMaintenanceState
   * Gets current maintenance state.
   */
  getMaintenanceStateUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

  /**
   * getSupportInfo
   * @param {string} countryCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  getSupportInfoUsingGETRaw(
    requestParameters: GetSupportInfoUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SupportInfo>>;

  /**
   * getSupportInfo
   */
  getSupportInfoUsingGET(
    requestParameters: GetSupportInfoUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SupportInfo>;

  /**
   * listCountries
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  listCountriesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<string>>>;

  /**
   * listCountries
   */
  listCountriesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>>;

  /**
   * setMaintenance
   * @summary Update the maintenance.
   * @param {boolean} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  setMaintenanceUsingPUTRaw(
    requestParameters: SetMaintenanceUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>>;

  /**
   * setMaintenance
   * Update the maintenance.
   */
  setMaintenanceUsingPUT(
    requestParameters: SetMaintenanceUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean>;

  /**
   * setSupportInfo
   * @param {string} countryCode
   * @param {SupportInfo} supportInfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupportControllerApiInterface
   */
  setSupportInfoUsingPUTRaw(
    requestParameters: SetSupportInfoUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>>;

  /**
   * setSupportInfo
   */
  setSupportInfoUsingPUT(
    requestParameters: SetSupportInfoUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void>;
}

/**
 *
 */
export class SupportControllerApi extends runtime.BaseAPI implements SupportControllerApiInterface {
  /**
   * downloadApi
   * Download a list of used microservice calls
   */
  async downloadApiUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/public/api/download`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * downloadApi
   * Download a list of used microservice calls
   */
  async downloadApiUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.downloadApiUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * getMaintenanceState
   * Gets current maintenance state.
   */
  async getMaintenanceStateUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/maintenance/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * getMaintenanceState
   * Gets current maintenance state.
   */
  async getMaintenanceStateUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
    const response = await this.getMaintenanceStateUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * getSupportInfo
   */
  async getSupportInfoUsingGETRaw(
    requestParameters: GetSupportInfoUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SupportInfo>> {
    if (requestParameters['countryCode'] == null) {
      throw new runtime.RequiredError(
        'countryCode',
        'Required parameter "countryCode" was null or undefined when calling getSupportInfoUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/info/{countryCode}`.replace(
          `{${'countryCode'}}`,
          encodeURIComponent(String(requestParameters['countryCode']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => SupportInfoFromJSON(jsonValue));
  }

  /**
   * getSupportInfo
   */
  async getSupportInfoUsingGET(
    requestParameters: GetSupportInfoUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SupportInfo> {
    const response = await this.getSupportInfoUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * listCountries
   */
  async listCountriesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<string>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/info/countries`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * listCountries
   */
  async listCountriesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
    const response = await this.listCountriesUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * setMaintenance
   * Update the maintenance.
   */
  async setMaintenanceUsingPUTRaw(
    requestParameters: SetMaintenanceUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters['state'] == null) {
      throw new runtime.RequiredError(
        'state',
        'Required parameter "state" was null or undefined when calling setMaintenanceUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/maintenance/{state}`.replace(
          `{${'state'}}`,
          encodeURIComponent(String(requestParameters['state']))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * setMaintenance
   * Update the maintenance.
   */
  async setMaintenanceUsingPUT(
    requestParameters: SetMaintenanceUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.setMaintenanceUsingPUTRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * setSupportInfo
   */
  async setSupportInfoUsingPUTRaw(
    requestParameters: SetSupportInfoUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['countryCode'] == null) {
      throw new runtime.RequiredError(
        'countryCode',
        'Required parameter "countryCode" was null or undefined when calling setSupportInfoUsingPUT().'
      );
    }

    if (requestParameters['supportInfo'] == null) {
      throw new runtime.RequiredError(
        'supportInfo',
        'Required parameter "supportInfo" was null or undefined when calling setSupportInfoUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/info/{countryCode}`.replace(
          `{${'countryCode'}}`,
          encodeURIComponent(String(requestParameters['countryCode']))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SupportInfoToJSON(requestParameters['supportInfo'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * setSupportInfo
   */
  async setSupportInfoUsingPUT(
    requestParameters: SetSupportInfoUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.setSupportInfoUsingPUTRaw(requestParameters, initOverrides);
  }
}
