/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceResponse } from './ApplianceResponse';
import {
  ApplianceResponseFromJSON,
  ApplianceResponseFromJSONTyped,
  ApplianceResponseToJSON,
  ApplianceResponseToJSONTyped
} from './ApplianceResponse';

/**
 *
 * @export
 * @interface AppliancesResponse
 */
export interface AppliancesResponse {
  /**
   *
   * @type {Array<ApplianceResponse>}
   * @memberof AppliancesResponse
   */
  applianceResponses?: Array<ApplianceResponse>;
}

/**
 * Check if a given object implements the AppliancesResponse interface.
 */
export function instanceOfAppliancesResponse(value: object): value is AppliancesResponse {
  return true;
}

export function AppliancesResponseFromJSON(json: any): AppliancesResponse {
  return AppliancesResponseFromJSONTyped(json, false);
}

export function AppliancesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppliancesResponse {
  if (json == null) {
    return json;
  }
  return {
    applianceResponses:
      json['applianceResponses'] == null
        ? undefined
        : (json['applianceResponses'] as Array<any>).map(ApplianceResponseFromJSON)
  };
}

export function AppliancesResponseToJSON(json: any): AppliancesResponse {
  return AppliancesResponseToJSONTyped(json, false);
}

export function AppliancesResponseToJSONTyped(
  value?: AppliancesResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    applianceResponses:
      value['applianceResponses'] == null
        ? undefined
        : (value['applianceResponses'] as Array<any>).map(ApplianceResponseToJSON)
  };
}
