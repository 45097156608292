/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { VersionInformation } from '../models/index';
import { VersionInformationFromJSON, VersionInformationToJSON } from '../models/index';

export interface GetCheckedServerVersionUsingGETRequest {
  frontendVersion: string;
}

/**
 * ConfigControllerApi - interface
 *
 * @export
 * @interface ConfigControllerApiInterface
 */
export interface ConfigControllerApiInterface {
  /**
   * getCheckedServerVersion
   * @summary Gets the version of the server and sends the client version
   * @param {string} frontendVersion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConfigControllerApiInterface
   */
  getCheckedServerVersionUsingGETRaw(
    requestParameters: GetCheckedServerVersionUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<VersionInformation>>;

  /**
   * getCheckedServerVersion
   * Gets the version of the server and sends the client version
   */
  getCheckedServerVersionUsingGET(
    requestParameters: GetCheckedServerVersionUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<VersionInformation>;
}

/**
 *
 */
export class ConfigControllerApi extends runtime.BaseAPI implements ConfigControllerApiInterface {
  /**
   * getCheckedServerVersion
   * Gets the version of the server and sends the client version
   */
  async getCheckedServerVersionUsingGETRaw(
    requestParameters: GetCheckedServerVersionUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<VersionInformation>> {
    if (requestParameters['frontendVersion'] == null) {
      throw new runtime.RequiredError(
        'frontendVersion',
        'Required parameter "frontendVersion" was null or undefined when calling getCheckedServerVersionUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/version/check/{frontendVersion}`.replace(
          `{${'frontendVersion'}}`,
          encodeURIComponent(String(requestParameters['frontendVersion']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => VersionInformationFromJSON(jsonValue));
  }

  /**
   * getCheckedServerVersion
   * Gets the version of the server and sends the client version
   */
  async getCheckedServerVersionUsingGET(
    requestParameters: GetCheckedServerVersionUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<VersionInformation> {
    const response = await this.getCheckedServerVersionUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
