/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  csvSeparator?: UserSettingsCsvSeparatorEnum;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  csvSeparatorChanged?: Date;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  googleAnalytics?: UserSettingsGoogleAnalyticsEnum;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  googleAnalyticsChanged?: Date;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  heartbeatView?: UserSettingsHeartbeatViewEnum;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  heartbeatViewChanged?: Date;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  language?: UserSettingsLanguageEnum;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  languageChanged?: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettings
   */
  readMessages?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  readMessagesChanged?: Date;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  sessionRenew?: UserSettingsSessionRenewEnum;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  sessionRenewChanged?: Date;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  termsOfUseVersion?: string;
  /**
   *
   * @type {Date}
   * @memberof UserSettings
   */
  termsOfUseVersionChanged?: Date;
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  valid?: boolean;
}

/**
 * @export
 */
export const UserSettingsCsvSeparatorEnum = {
  COMMA: 'Comma',
  SEMICOLON: 'Semicolon'
} as const;
export type UserSettingsCsvSeparatorEnum =
  (typeof UserSettingsCsvSeparatorEnum)[keyof typeof UserSettingsCsvSeparatorEnum];

/**
 * @export
 */
export const UserSettingsGoogleAnalyticsEnum = {
  YES: 'YES',
  NO: 'NO',
  ASK: 'ASK'
} as const;
export type UserSettingsGoogleAnalyticsEnum =
  (typeof UserSettingsGoogleAnalyticsEnum)[keyof typeof UserSettingsGoogleAnalyticsEnum];

/**
 * @export
 */
export const UserSettingsHeartbeatViewEnum = {
  PIE: 'PIE',
  BAR: 'BAR'
} as const;
export type UserSettingsHeartbeatViewEnum =
  (typeof UserSettingsHeartbeatViewEnum)[keyof typeof UserSettingsHeartbeatViewEnum];

/**
 * @export
 */
export const UserSettingsLanguageEnum = {
  DE_DE: 'deDE',
  EN_US: 'enUS'
} as const;
export type UserSettingsLanguageEnum = (typeof UserSettingsLanguageEnum)[keyof typeof UserSettingsLanguageEnum];

/**
 * @export
 */
export const UserSettingsSessionRenewEnum = {
  YES: 'YES',
  NO: 'NO',
  ASK: 'ASK'
} as const;
export type UserSettingsSessionRenewEnum =
  (typeof UserSettingsSessionRenewEnum)[keyof typeof UserSettingsSessionRenewEnum];

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): value is UserSettings {
  return true;
}

export function UserSettingsFromJSON(json: any): UserSettings {
  return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
  if (json == null) {
    return json;
  }
  return {
    csvSeparator: json['csvSeparator'] == null ? undefined : json['csvSeparator'],
    csvSeparatorChanged: json['csvSeparatorChanged'] == null ? undefined : new Date(json['csvSeparatorChanged']),
    googleAnalytics: json['googleAnalytics'] == null ? undefined : json['googleAnalytics'],
    googleAnalyticsChanged:
      json['googleAnalyticsChanged'] == null ? undefined : new Date(json['googleAnalyticsChanged']),
    heartbeatView: json['heartbeatView'] == null ? undefined : json['heartbeatView'],
    heartbeatViewChanged: json['heartbeatViewChanged'] == null ? undefined : new Date(json['heartbeatViewChanged']),
    language: json['language'] == null ? undefined : json['language'],
    languageChanged: json['languageChanged'] == null ? undefined : new Date(json['languageChanged']),
    readMessages: json['readMessages'] == null ? undefined : json['readMessages'],
    readMessagesChanged: json['readMessagesChanged'] == null ? undefined : new Date(json['readMessagesChanged']),
    sessionRenew: json['sessionRenew'] == null ? undefined : json['sessionRenew'],
    sessionRenewChanged: json['sessionRenewChanged'] == null ? undefined : new Date(json['sessionRenewChanged']),
    termsOfUseVersion: json['termsOfUseVersion'] == null ? undefined : json['termsOfUseVersion'],
    termsOfUseVersionChanged:
      json['termsOfUseVersionChanged'] == null ? undefined : new Date(json['termsOfUseVersionChanged']),
    valid: json['valid'] == null ? undefined : json['valid']
  };
}

export function UserSettingsToJSON(json: any): UserSettings {
  return UserSettingsToJSONTyped(json, false);
}

export function UserSettingsToJSONTyped(value?: UserSettings | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    csvSeparator: value['csvSeparator'],
    csvSeparatorChanged: value['csvSeparatorChanged'] == null ? undefined : value['csvSeparatorChanged'].toISOString(),
    googleAnalytics: value['googleAnalytics'],
    googleAnalyticsChanged:
      value['googleAnalyticsChanged'] == null ? undefined : value['googleAnalyticsChanged'].toISOString(),
    heartbeatView: value['heartbeatView'],
    heartbeatViewChanged:
      value['heartbeatViewChanged'] == null ? undefined : value['heartbeatViewChanged'].toISOString(),
    language: value['language'],
    languageChanged: value['languageChanged'] == null ? undefined : value['languageChanged'].toISOString(),
    readMessages: value['readMessages'],
    readMessagesChanged: value['readMessagesChanged'] == null ? undefined : value['readMessagesChanged'].toISOString(),
    sessionRenew: value['sessionRenew'],
    sessionRenewChanged: value['sessionRenewChanged'] == null ? undefined : value['sessionRenewChanged'].toISOString(),
    termsOfUseVersion: value['termsOfUseVersion'],
    termsOfUseVersionChanged:
      value['termsOfUseVersionChanged'] == null ? undefined : value['termsOfUseVersionChanged'].toISOString(),
    valid: value['valid']
  };
}
