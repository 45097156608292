/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { ContinuationAlarmToken, FilteredMergedAlarmsResponse } from '../models/index';
import {
  ContinuationAlarmTokenFromJSON,
  ContinuationAlarmTokenToJSON,
  FilteredMergedAlarmsResponseFromJSON,
  FilteredMergedAlarmsResponseToJSON
} from '../models/index';

export interface FilterMergedAlarmsUsingPUTRequest {
  filter: FilterMergedAlarmsUsingPUTFilterEnum;
  continuationAlarmToken: ContinuationAlarmToken;
  gatewayId?: string;
  gatewaySerialNumber?: string;
  smartModuleSerialNumber?: string;
  smartModuleVfId?: string;
  virtualFridgeId?: string;
}

/**
 * AlarmControllerApi - interface
 *
 * @export
 * @interface AlarmControllerApiInterface
 */
export interface AlarmControllerApiInterface {
  /**
   * filterMergedAlarms
   * @summary Gets list with all alarms related to device which we search by
   * @param {'APPLIANCE' | 'GATEWAY' | 'SMART_MODULE' | 'MERGED'} filter
   * @param {ContinuationAlarmToken} continuationAlarmToken
   * @param {string} [gatewayId]
   * @param {string} [gatewaySerialNumber]
   * @param {string} [smartModuleSerialNumber]
   * @param {string} [smartModuleVfId]
   * @param {string} [virtualFridgeId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AlarmControllerApiInterface
   */
  filterMergedAlarmsUsingPUTRaw(
    requestParameters: FilterMergedAlarmsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FilteredMergedAlarmsResponse>>;

  /**
   * filterMergedAlarms
   * Gets list with all alarms related to device which we search by
   */
  filterMergedAlarmsUsingPUT(
    requestParameters: FilterMergedAlarmsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FilteredMergedAlarmsResponse>;
}

/**
 *
 */
export class AlarmControllerApi extends runtime.BaseAPI implements AlarmControllerApiInterface {
  /**
   * filterMergedAlarms
   * Gets list with all alarms related to device which we search by
   */
  async filterMergedAlarmsUsingPUTRaw(
    requestParameters: FilterMergedAlarmsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FilteredMergedAlarmsResponse>> {
    if (requestParameters['filter'] == null) {
      throw new runtime.RequiredError(
        'filter',
        'Required parameter "filter" was null or undefined when calling filterMergedAlarmsUsingPUT().'
      );
    }

    if (requestParameters['continuationAlarmToken'] == null) {
      throw new runtime.RequiredError(
        'continuationAlarmToken',
        'Required parameter "continuationAlarmToken" was null or undefined when calling filterMergedAlarmsUsingPUT().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['filter'] != null) {
      queryParameters['filter'] = requestParameters['filter'];
    }

    if (requestParameters['gatewayId'] != null) {
      queryParameters['gatewayId'] = requestParameters['gatewayId'];
    }

    if (requestParameters['gatewaySerialNumber'] != null) {
      queryParameters['gatewaySerialNumber'] = requestParameters['gatewaySerialNumber'];
    }

    if (requestParameters['smartModuleSerialNumber'] != null) {
      queryParameters['smartModuleSerialNumber'] = requestParameters['smartModuleSerialNumber'];
    }

    if (requestParameters['smartModuleVfId'] != null) {
      queryParameters['smartModuleVfId'] = requestParameters['smartModuleVfId'];
    }

    if (requestParameters['virtualFridgeId'] != null) {
      queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/filteredmergedalarms`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ContinuationAlarmTokenToJSON(requestParameters['continuationAlarmToken'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FilteredMergedAlarmsResponseFromJSON(jsonValue));
  }

  /**
   * filterMergedAlarms
   * Gets list with all alarms related to device which we search by
   */
  async filterMergedAlarmsUsingPUT(
    requestParameters: FilterMergedAlarmsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FilteredMergedAlarmsResponse> {
    const response = await this.filterMergedAlarmsUsingPUTRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const FilterMergedAlarmsUsingPUTFilterEnum = {
  APPLIANCE: 'APPLIANCE',
  GATEWAY: 'GATEWAY',
  SMART_MODULE: 'SMART_MODULE',
  MERGED: 'MERGED'
} as const;
export type FilterMergedAlarmsUsingPUTFilterEnum =
  (typeof FilterMergedAlarmsUsingPUTFilterEnum)[keyof typeof FilterMergedAlarmsUsingPUTFilterEnum];
