/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CompanyResponse,
  OrganizationSettings,
  OrganizationsPerCountryResponse,
  StatisticsData
} from '../models/index';
import {
  CompanyResponseFromJSON,
  CompanyResponseToJSON,
  OrganizationSettingsFromJSON,
  OrganizationSettingsToJSON,
  OrganizationsPerCountryResponseFromJSON,
  OrganizationsPerCountryResponseToJSON,
  StatisticsDataFromJSON,
  StatisticsDataToJSON
} from '../models/index';

export interface GetCompanyDataUsingGETRequest {
  deviceType: GetCompanyDataUsingGETDeviceTypeEnum;
  uuid?: string;
}

export interface StoreOrganizationLanguageUsingPUTRequest {
  body: string;
}

export interface StoreOrganizationSettingsUsingPUTRequest {
  organizationSettings: OrganizationSettings;
}

/**
 * OrganizationControllerApi - interface
 *
 * @export
 * @interface OrganizationControllerApiInterface
 */
export interface OrganizationControllerApiInterface {
  /**
   * getCompanyData
   * @summary Gets the company information for specific device.
   * @param {'GATEWAY' | 'APPLIANCE'} deviceType
   * @param {string} [uuid]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  getCompanyDataUsingGETRaw(
    requestParameters: GetCompanyDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CompanyResponse>>;

  /**
   * getCompanyData
   * Gets the company information for specific device.
   */
  getCompanyDataUsingGET(
    requestParameters: GetCompanyDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CompanyResponse>;

  /**
   * getCountryStats
   * @summary Getting stats per country.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  getCountryStatsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrganizationsPerCountryResponse>>;

  /**
   * getCountryStats
   * Getting stats per country.
   */
  getCountryStatsUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrganizationsPerCountryResponse>;

  /**
   * getOrganizationSettings
   * @summary Gets the Organization settings for the user specified in the UserContext.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  getOrganizationSettingsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrganizationSettings>>;

  /**
   * getOrganizationSettings
   * Gets the Organization settings for the user specified in the UserContext.
   */
  getOrganizationSettingsUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrganizationSettings>;

  /**
   * getStatisticsData
   * @summary Gets statistics data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  getStatisticsDataUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StatisticsData>>;

  /**
   * getStatisticsData
   * Gets statistics data
   */
  getStatisticsDataUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatisticsData>;

  /**
   * storeOrganizationLanguage
   * @summary Stores the language for an organization.
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  storeOrganizationLanguageUsingPUTRaw(
    requestParameters: StoreOrganizationLanguageUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>>;

  /**
   * storeOrganizationLanguage
   * Stores the language for an organization.
   */
  storeOrganizationLanguageUsingPUT(
    requestParameters: StoreOrganizationLanguageUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void>;

  /**
   * storeOrganizationSettings
   * @summary Stores the settings for the organization.
   * @param {OrganizationSettings} organizationSettings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationControllerApiInterface
   */
  storeOrganizationSettingsUsingPUTRaw(
    requestParameters: StoreOrganizationSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>>;

  /**
   * storeOrganizationSettings
   * Stores the settings for the organization.
   */
  storeOrganizationSettingsUsingPUT(
    requestParameters: StoreOrganizationSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void>;
}

/**
 *
 */
export class OrganizationControllerApi extends runtime.BaseAPI implements OrganizationControllerApiInterface {
  /**
   * getCompanyData
   * Gets the company information for specific device.
   */
  async getCompanyDataUsingGETRaw(
    requestParameters: GetCompanyDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CompanyResponse>> {
    if (requestParameters['deviceType'] == null) {
      throw new runtime.RequiredError(
        'deviceType',
        'Required parameter "deviceType" was null or undefined when calling getCompanyDataUsingGET().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['deviceType'] != null) {
      queryParameters['deviceType'] = requestParameters['deviceType'];
    }

    if (requestParameters['uuid'] != null) {
      queryParameters['uuid'] = requestParameters['uuid'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/company/info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => CompanyResponseFromJSON(jsonValue));
  }

  /**
   * getCompanyData
   * Gets the company information for specific device.
   */
  async getCompanyDataUsingGET(
    requestParameters: GetCompanyDataUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CompanyResponse> {
    const response = await this.getCompanyDataUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getCountryStats
   * Getting stats per country.
   */
  async getCountryStatsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrganizationsPerCountryResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/countries/stats`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => OrganizationsPerCountryResponseFromJSON(jsonValue));
  }

  /**
   * getCountryStats
   * Getting stats per country.
   */
  async getCountryStatsUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrganizationsPerCountryResponse> {
    const response = await this.getCountryStatsUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * getOrganizationSettings
   * Gets the Organization settings for the user specified in the UserContext.
   */
  async getOrganizationSettingsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrganizationSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/organization/settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => OrganizationSettingsFromJSON(jsonValue));
  }

  /**
   * getOrganizationSettings
   * Gets the Organization settings for the user specified in the UserContext.
   */
  async getOrganizationSettingsUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrganizationSettings> {
    const response = await this.getOrganizationSettingsUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * getStatisticsData
   * Gets statistics data
   */
  async getStatisticsDataUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StatisticsData>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/support/statistics`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StatisticsDataFromJSON(jsonValue));
  }

  /**
   * getStatisticsData
   * Gets statistics data
   */
  async getStatisticsDataUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatisticsData> {
    const response = await this.getStatisticsDataUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * storeOrganizationLanguage
   * Stores the language for an organization.
   */
  async storeOrganizationLanguageUsingPUTRaw(
    requestParameters: StoreOrganizationLanguageUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling storeOrganizationLanguageUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/admin/organization/language`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * storeOrganizationLanguage
   * Stores the language for an organization.
   */
  async storeOrganizationLanguageUsingPUT(
    requestParameters: StoreOrganizationLanguageUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.storeOrganizationLanguageUsingPUTRaw(requestParameters, initOverrides);
  }

  /**
   * storeOrganizationSettings
   * Stores the settings for the organization.
   */
  async storeOrganizationSettingsUsingPUTRaw(
    requestParameters: StoreOrganizationSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['organizationSettings'] == null) {
      throw new runtime.RequiredError(
        'organizationSettings',
        'Required parameter "organizationSettings" was null or undefined when calling storeOrganizationSettingsUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/admin/organization/settings`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationSettingsToJSON(requestParameters['organizationSettings'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * storeOrganizationSettings
   * Stores the settings for the organization.
   */
  async storeOrganizationSettingsUsingPUT(
    requestParameters: StoreOrganizationSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.storeOrganizationSettingsUsingPUTRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const GetCompanyDataUsingGETDeviceTypeEnum = {
  GATEWAY: 'GATEWAY',
  APPLIANCE: 'APPLIANCE'
} as const;
export type GetCompanyDataUsingGETDeviceTypeEnum =
  (typeof GetCompanyDataUsingGETDeviceTypeEnum)[keyof typeof GetCompanyDataUsingGETDeviceTypeEnum];
