/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SmartModuleZoneState } from './SmartModuleZoneState';
import {
  SmartModuleZoneStateFromJSON,
  SmartModuleZoneStateFromJSONTyped,
  SmartModuleZoneStateToJSON,
  SmartModuleZoneStateToJSONTyped
} from './SmartModuleZoneState';

/**
 *
 * @export
 * @interface SmartModuleState
 */
export interface SmartModuleState {
  /**
   *
   * @type {Array<string>}
   * @memberof SmartModuleState
   */
  errorTypes?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof SmartModuleState
   */
  lastTemperatureUpdate?: Date;
  /**
   *
   * @type {string}
   * @memberof SmartModuleState
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleState
   */
  virtualFridgeId?: string;
  /**
   *
   * @type {Array<SmartModuleZoneState>}
   * @memberof SmartModuleState
   */
  zones?: Array<SmartModuleZoneState>;
}

/**
 * Check if a given object implements the SmartModuleState interface.
 */
export function instanceOfSmartModuleState(value: object): value is SmartModuleState {
  return true;
}

export function SmartModuleStateFromJSON(json: any): SmartModuleState {
  return SmartModuleStateFromJSONTyped(json, false);
}

export function SmartModuleStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleState {
  if (json == null) {
    return json;
  }
  return {
    errorTypes: json['errorTypes'] == null ? undefined : json['errorTypes'],
    lastTemperatureUpdate: json['lastTemperatureUpdate'] == null ? undefined : new Date(json['lastTemperatureUpdate']),
    state: json['state'] == null ? undefined : json['state'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    zones: json['zones'] == null ? undefined : (json['zones'] as Array<any>).map(SmartModuleZoneStateFromJSON)
  };
}

export function SmartModuleStateToJSON(json: any): SmartModuleState {
  return SmartModuleStateToJSONTyped(json, false);
}

export function SmartModuleStateToJSONTyped(
  value?: SmartModuleState | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    errorTypes: value['errorTypes'],
    lastTemperatureUpdate:
      value['lastTemperatureUpdate'] == null ? undefined : value['lastTemperatureUpdate'].toISOString(),
    state: value['state'],
    virtualFridgeId: value['virtualFridgeId'],
    zones: value['zones'] == null ? undefined : (value['zones'] as Array<any>).map(SmartModuleZoneStateToJSON)
  };
}
