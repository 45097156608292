/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IBotGatewayState
 */
export interface IBotGatewayState {
  /**
   *
   * @type {string}
   * @memberof IBotGatewayState
   */
  cmId?: string;
  /**
   *
   * @type {string}
   * @memberof IBotGatewayState
   */
  cmInstalledSoftwareVersion?: string;
}

/**
 * Check if a given object implements the IBotGatewayState interface.
 */
export function instanceOfIBotGatewayState(value: object): value is IBotGatewayState {
  return true;
}

export function IBotGatewayStateFromJSON(json: any): IBotGatewayState {
  return IBotGatewayStateFromJSONTyped(json, false);
}

export function IBotGatewayStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IBotGatewayState {
  if (json == null) {
    return json;
  }
  return {
    cmId: json['cmId'] == null ? undefined : json['cmId'],
    cmInstalledSoftwareVersion:
      json['cmInstalledSoftwareVersion'] == null ? undefined : json['cmInstalledSoftwareVersion']
  };
}

export function IBotGatewayStateToJSON(json: any): IBotGatewayState {
  return IBotGatewayStateToJSONTyped(json, false);
}

export function IBotGatewayStateToJSONTyped(
  value?: IBotGatewayState | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    cmId: value['cmId'],
    cmInstalledSoftwareVersion: value['cmInstalledSoftwareVersion']
  };
}
