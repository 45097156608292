/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SupportUser
 */
export interface SupportUser {
  /**
   *
   * @type {string}
   * @memberof SupportUser
   */
  upn?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SupportUser
   */
  userAccessLevels?: Array<SupportUserUserAccessLevelsEnum>;
  /**
   *
   * @type {boolean}
   * @memberof SupportUser
   */
  userActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof SupportUser
   */
  userEmail?: string;
  /**
   *
   * @type {string}
   * @memberof SupportUser
   */
  userName?: string;
}

/**
 * @export
 */
export const SupportUserUserAccessLevelsEnum = {
  BUSINESS: 'BUSINESS',
  SUPPORT: 'SUPPORT',
  DEVELOPER: 'DEVELOPER',
  SALES: 'SALES',
  NO_ACCESS: 'NO_ACCESS'
} as const;
export type SupportUserUserAccessLevelsEnum =
  (typeof SupportUserUserAccessLevelsEnum)[keyof typeof SupportUserUserAccessLevelsEnum];

/**
 * Check if a given object implements the SupportUser interface.
 */
export function instanceOfSupportUser(value: object): value is SupportUser {
  return true;
}

export function SupportUserFromJSON(json: any): SupportUser {
  return SupportUserFromJSONTyped(json, false);
}

export function SupportUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportUser {
  if (json == null) {
    return json;
  }
  return {
    upn: json['upn'] == null ? undefined : json['upn'],
    userAccessLevels: json['userAccessLevels'] == null ? undefined : json['userAccessLevels'],
    userActive: json['userActive'] == null ? undefined : json['userActive'],
    userEmail: json['userEmail'] == null ? undefined : json['userEmail'],
    userName: json['userName'] == null ? undefined : json['userName']
  };
}

export function SupportUserToJSON(json: any): SupportUser {
  return SupportUserToJSONTyped(json, false);
}

export function SupportUserToJSONTyped(value?: SupportUser | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    upn: value['upn'],
    userAccessLevels: value['userAccessLevels'],
    userActive: value['userActive'],
    userEmail: value['userEmail'],
    userName: value['userName']
  };
}
