/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AccessCheckResponse,
  ColleagueResponse,
  SupportUser,
  UserInfoResponse,
  UserSettings
} from '../models/index';
import {
  AccessCheckResponseFromJSON,
  AccessCheckResponseToJSON,
  ColleagueResponseFromJSON,
  ColleagueResponseToJSON,
  SupportUserFromJSON,
  SupportUserToJSON,
  UserInfoResponseFromJSON,
  UserInfoResponseToJSON,
  UserSettingsFromJSON,
  UserSettingsToJSON
} from '../models/index';

export interface AddSupportUserUsingPOSTRequest {
  supportUser: SupportUser;
}

export interface DeleteSupportUserUsingDELETERequest {
  upn: string;
}

export interface EditSupportUserUsingPUTRequest {
  supportUser: SupportUser;
}

export interface GetSupportUsersUsingGETRequest {
  queryString?: string;
}

export interface StoreUserSettingsUsingPUTRequest {
  userSettings: UserSettings;
}

/**
 * UserControllerApi - interface
 *
 * @export
 * @interface UserControllerApiInterface
 */
export interface UserControllerApiInterface {
  /**
   * addSupportUser
   * @summary Creates a new SupportUser entry.
   * @param {SupportUser} supportUser
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  addSupportUserUsingPOSTRaw(
    requestParameters: AddSupportUserUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>>;

  /**
   * addSupportUser
   * Creates a new SupportUser entry.
   */
  addSupportUserUsingPOST(
    requestParameters: AddSupportUserUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string>;

  /**
   * checkAccess
   * @summary Checks whether the current user can access the application.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  checkAccessUsingPOSTRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AccessCheckResponse>>;

  /**
   * checkAccess
   * Checks whether the current user can access the application.
   */
  checkAccessUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessCheckResponse>;

  /**
   * checkUser
   * @summary Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  checkUserUsingPOSTRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserInfoResponse>>;

  /**
   * checkUser
   * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   */
  checkUserUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoResponse>;

  /**
   * deleteSupportUser
   * @summary Deletes a SupportUser entry.
   * @param {string} upn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  deleteSupportUserUsingDELETERaw(
    requestParameters: DeleteSupportUserUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>>;

  /**
   * deleteSupportUser
   * Deletes a SupportUser entry.
   */
  deleteSupportUserUsingDELETE(
    requestParameters: DeleteSupportUserUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string>;

  /**
   * editSupportUser
   * @summary Edits an existing SupportUser entry.
   * @param {SupportUser} supportUser
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  editSupportUserUsingPUTRaw(
    requestParameters: EditSupportUserUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>>;

  /**
   * editSupportUser
   * Edits an existing SupportUser entry.
   */
  editSupportUserUsingPUT(
    requestParameters: EditSupportUserUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string>;

  /**
   * getColleagues
   * @summary Gets the colleagues information for a user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  getColleaguesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ColleagueResponse>>;

  /**
   * getColleagues
   * Gets the colleagues information for a user
   */
  getColleaguesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColleagueResponse>;

  /**
   * getSupportUsers
   * @summary Gets the list of support users with optionally applied query string.
   * @param {string} [queryString]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  getSupportUsersUsingGETRaw(
    requestParameters: GetSupportUsersUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<SupportUser>>>;

  /**
   * getSupportUsers
   * Gets the list of support users with optionally applied query string.
   */
  getSupportUsersUsingGET(
    requestParameters: GetSupportUsersUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<SupportUser>>;

  /**
   * getUserSettings
   * @summary Gets the user settings for the user specified in the UserContext.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  getUserSettingsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserSettings>>;

  /**
   * getUserSettings
   * Gets the user settings for the user specified in the UserContext.
   */
  getUserSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings>;

  /**
   * storeUserSettings
   * @summary Stores the UserContext for the user.
   * @param {UserSettings} userSettings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApiInterface
   */
  storeUserSettingsUsingPUTRaw(
    requestParameters: StoreUserSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>>;

  /**
   * storeUserSettings
   * Stores the UserContext for the user.
   */
  storeUserSettingsUsingPUT(
    requestParameters: StoreUserSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void>;
}

/**
 *
 */
export class UserControllerApi extends runtime.BaseAPI implements UserControllerApiInterface {
  /**
   * addSupportUser
   * Creates a new SupportUser entry.
   */
  async addSupportUserUsingPOSTRaw(
    requestParameters: AddSupportUserUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters['supportUser'] == null) {
      throw new runtime.RequiredError(
        'supportUser',
        'Required parameter "supportUser" was null or undefined when calling addSupportUserUsingPOST().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/support/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SupportUserToJSON(requestParameters['supportUser'])
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * addSupportUser
   * Creates a new SupportUser entry.
   */
  async addSupportUserUsingPOST(
    requestParameters: AddSupportUserUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.addSupportUserUsingPOSTRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * checkAccess
   * Checks whether the current user can access the application.
   */
  async checkAccessUsingPOSTRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AccessCheckResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/access/check`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => AccessCheckResponseFromJSON(jsonValue));
  }

  /**
   * checkAccess
   * Checks whether the current user can access the application.
   */
  async checkAccessUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessCheckResponse> {
    const response = await this.checkAccessUsingPOSTRaw(initOverrides);
    return await response.value();
  }

  /**
   * checkUser
   * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   */
  async checkUserUsingPOSTRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserInfoResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/check`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => UserInfoResponseFromJSON(jsonValue));
  }

  /**
   * checkUser
   * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   */
  async checkUserUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoResponse> {
    const response = await this.checkUserUsingPOSTRaw(initOverrides);
    return await response.value();
  }

  /**
   * deleteSupportUser
   * Deletes a SupportUser entry.
   */
  async deleteSupportUserUsingDELETERaw(
    requestParameters: DeleteSupportUserUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters['upn'] == null) {
      throw new runtime.RequiredError(
        'upn',
        'Required parameter "upn" was null or undefined when calling deleteSupportUserUsingDELETE().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/support/delete/{upn}`.replace(`{${'upn'}}`, encodeURIComponent(String(requestParameters['upn']))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * deleteSupportUser
   * Deletes a SupportUser entry.
   */
  async deleteSupportUserUsingDELETE(
    requestParameters: DeleteSupportUserUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.deleteSupportUserUsingDELETERaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * editSupportUser
   * Edits an existing SupportUser entry.
   */
  async editSupportUserUsingPUTRaw(
    requestParameters: EditSupportUserUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters['supportUser'] == null) {
      throw new runtime.RequiredError(
        'supportUser',
        'Required parameter "supportUser" was null or undefined when calling editSupportUserUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/support/edit`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SupportUserToJSON(requestParameters['supportUser'])
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * editSupportUser
   * Edits an existing SupportUser entry.
   */
  async editSupportUserUsingPUT(
    requestParameters: EditSupportUserUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.editSupportUserUsingPUTRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getColleagues
   * Gets the colleagues information for a user
   */
  async getColleaguesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ColleagueResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/colleagues`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ColleagueResponseFromJSON(jsonValue));
  }

  /**
   * getColleagues
   * Gets the colleagues information for a user
   */
  async getColleaguesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColleagueResponse> {
    const response = await this.getColleaguesUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * getSupportUsers
   * Gets the list of support users with optionally applied query string.
   */
  async getSupportUsersUsingGETRaw(
    requestParameters: GetSupportUsersUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<SupportUser>>> {
    const queryParameters: any = {};

    if (requestParameters['queryString'] != null) {
      queryParameters['queryString'] = requestParameters['queryString'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/support`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(SupportUserFromJSON));
  }

  /**
   * getSupportUsers
   * Gets the list of support users with optionally applied query string.
   */
  async getSupportUsersUsingGET(
    requestParameters: GetSupportUsersUsingGETRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<SupportUser>> {
    const response = await this.getSupportUsersUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getUserSettings
   * Gets the user settings for the user specified in the UserContext.
   */
  async getUserSettingsUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => UserSettingsFromJSON(jsonValue));
  }

  /**
   * getUserSettings
   * Gets the user settings for the user specified in the UserContext.
   */
  async getUserSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
    const response = await this.getUserSettingsUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * storeUserSettings
   * Stores the UserContext for the user.
   */
  async storeUserSettingsUsingPUTRaw(
    requestParameters: StoreUserSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userSettings'] == null) {
      throw new runtime.RequiredError(
        'userSettings',
        'Required parameter "userSettings" was null or undefined when calling storeUserSettingsUsingPUT().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/user/settings`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UserSettingsToJSON(requestParameters['userSettings'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * storeUserSettings
   * Stores the UserContext for the user.
   */
  async storeUserSettingsUsingPUT(
    requestParameters: StoreUserSettingsUsingPUTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.storeUserSettingsUsingPUTRaw(requestParameters, initOverrides);
  }
}
