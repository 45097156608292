/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationAppliance } from './OrganizationAppliance';
import {
  OrganizationApplianceFromJSON,
  OrganizationApplianceFromJSONTyped,
  OrganizationApplianceToJSON,
  OrganizationApplianceToJSONTyped
} from './OrganizationAppliance';

/**
 *
 * @export
 * @interface OrganizationGroup
 */
export interface OrganizationGroup {
  /**
   *
   * @type {Array<OrganizationAppliance>}
   * @memberof OrganizationGroup
   */
  appliances?: Array<OrganizationAppliance>;
  /**
   *
   * @type {string}
   * @memberof OrganizationGroup
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationGroup
   */
  name?: string;
}

/**
 * Check if a given object implements the OrganizationGroup interface.
 */
export function instanceOfOrganizationGroup(value: object): value is OrganizationGroup {
  return true;
}

export function OrganizationGroupFromJSON(json: any): OrganizationGroup {
  return OrganizationGroupFromJSONTyped(json, false);
}

export function OrganizationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationGroup {
  if (json == null) {
    return json;
  }
  return {
    appliances:
      json['appliances'] == null ? undefined : (json['appliances'] as Array<any>).map(OrganizationApplianceFromJSON),
    groupId: json['groupId'] == null ? undefined : json['groupId'],
    name: json['name'] == null ? undefined : json['name']
  };
}

export function OrganizationGroupToJSON(json: any): OrganizationGroup {
  return OrganizationGroupToJSONTyped(json, false);
}

export function OrganizationGroupToJSONTyped(
  value?: OrganizationGroup | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    appliances:
      value['appliances'] == null ? undefined : (value['appliances'] as Array<any>).map(OrganizationApplianceToJSON),
    groupId: value['groupId'],
    name: value['name']
  };
}
