/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  externalOrganizationId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  internalOrganizationId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  upn?: string;
}

/**
 * Check if a given object implements the OrganizationResponse interface.
 */
export function instanceOfOrganizationResponse(value: object): value is OrganizationResponse {
  return true;
}

export function OrganizationResponseFromJSON(json: any): OrganizationResponse {
  return OrganizationResponseFromJSONTyped(json, false);
}

export function OrganizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponse {
  if (json == null) {
    return json;
  }
  return {
    externalOrganizationId: json['externalOrganizationId'] == null ? undefined : json['externalOrganizationId'],
    internalOrganizationId: json['internalOrganizationId'] == null ? undefined : json['internalOrganizationId'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function OrganizationResponseToJSON(json: any): OrganizationResponse {
  return OrganizationResponseToJSONTyped(json, false);
}

export function OrganizationResponseToJSONTyped(
  value?: OrganizationResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    externalOrganizationId: value['externalOrganizationId'],
    internalOrganizationId: value['internalOrganizationId'],
    upn: value['upn']
  };
}
