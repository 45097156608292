/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationsPerCountry } from './OrganizationsPerCountry';
import {
  OrganizationsPerCountryFromJSON,
  OrganizationsPerCountryFromJSONTyped,
  OrganizationsPerCountryToJSON,
  OrganizationsPerCountryToJSONTyped
} from './OrganizationsPerCountry';

/**
 *
 * @export
 * @interface OrganizationsPerCountryResponse
 */
export interface OrganizationsPerCountryResponse {
  /**
   *
   * @type {Array<OrganizationsPerCountry>}
   * @memberof OrganizationsPerCountryResponse
   */
  organizationsPerCountries?: Array<OrganizationsPerCountry>;
}

/**
 * Check if a given object implements the OrganizationsPerCountryResponse interface.
 */
export function instanceOfOrganizationsPerCountryResponse(value: object): value is OrganizationsPerCountryResponse {
  return true;
}

export function OrganizationsPerCountryResponseFromJSON(json: any): OrganizationsPerCountryResponse {
  return OrganizationsPerCountryResponseFromJSONTyped(json, false);
}

export function OrganizationsPerCountryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationsPerCountryResponse {
  if (json == null) {
    return json;
  }
  return {
    organizationsPerCountries:
      json['organizationsPerCountries'] == null
        ? undefined
        : (json['organizationsPerCountries'] as Array<any>).map(OrganizationsPerCountryFromJSON)
  };
}

export function OrganizationsPerCountryResponseToJSON(json: any): OrganizationsPerCountryResponse {
  return OrganizationsPerCountryResponseToJSONTyped(json, false);
}

export function OrganizationsPerCountryResponseToJSONTyped(
  value?: OrganizationsPerCountryResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    organizationsPerCountries:
      value['organizationsPerCountries'] == null
        ? undefined
        : (value['organizationsPerCountries'] as Array<any>).map(OrganizationsPerCountryToJSON)
  };
}
