/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApplianceState
 */
export interface ApplianceState {
  /**
   *
   * @type {Array<string>}
   * @memberof ApplianceState
   */
  alarmTypes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ApplianceState
   */
  deviceAlarmDelay?: number;
  /**
   *
   * @type {string}
   * @memberof ApplianceState
   */
  gatewayState?: string;
  /**
   *
   * @type {number}
   * @memberof ApplianceState
   */
  maxLimit?: number;
  /**
   *
   * @type {string}
   * @memberof ApplianceState
   */
  measurementUnit?: string;
  /**
   *
   * @type {number}
   * @memberof ApplianceState
   */
  minLimit?: number;
  /**
   *
   * @type {number}
   * @memberof ApplianceState
   */
  setTemperature?: number;
  /**
   *
   * @type {Date}
   * @memberof ApplianceState
   */
  since?: Date;
  /**
   *
   * @type {string}
   * @memberof ApplianceState
   */
  state?: string;
  /**
   *
   * @type {number}
   * @memberof ApplianceState
   */
  temperature?: number;
  /**
   *
   * @type {string}
   * @memberof ApplianceState
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the ApplianceState interface.
 */
export function instanceOfApplianceState(value: object): value is ApplianceState {
  return true;
}

export function ApplianceStateFromJSON(json: any): ApplianceState {
  return ApplianceStateFromJSONTyped(json, false);
}

export function ApplianceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceState {
  if (json == null) {
    return json;
  }
  return {
    alarmTypes: json['alarmTypes'] == null ? undefined : json['alarmTypes'],
    deviceAlarmDelay: json['deviceAlarmDelay'] == null ? undefined : json['deviceAlarmDelay'],
    gatewayState: json['gatewayState'] == null ? undefined : json['gatewayState'],
    maxLimit: json['maxLimit'] == null ? undefined : json['maxLimit'],
    measurementUnit: json['measurementUnit'] == null ? undefined : json['measurementUnit'],
    minLimit: json['minLimit'] == null ? undefined : json['minLimit'],
    setTemperature: json['setTemperature'] == null ? undefined : json['setTemperature'],
    since: json['since'] == null ? undefined : new Date(json['since']),
    state: json['state'] == null ? undefined : json['state'],
    temperature: json['temperature'] == null ? undefined : json['temperature'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function ApplianceStateToJSON(json: any): ApplianceState {
  return ApplianceStateToJSONTyped(json, false);
}

export function ApplianceStateToJSONTyped(value?: ApplianceState | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    alarmTypes: value['alarmTypes'],
    deviceAlarmDelay: value['deviceAlarmDelay'],
    gatewayState: value['gatewayState'],
    maxLimit: value['maxLimit'],
    measurementUnit: value['measurementUnit'],
    minLimit: value['minLimit'],
    setTemperature: value['setTemperature'],
    since: value['since'] == null ? undefined : value['since'].toISOString(),
    state: value['state'],
    temperature: value['temperature'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
