/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationMetrics
 */
export interface OrganizationMetrics {
  /**
   *
   * @type {number}
   * @memberof OrganizationMetrics
   */
  organizationCount?: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationMetrics
   */
  userCount?: number;
}

/**
 * Check if a given object implements the OrganizationMetrics interface.
 */
export function instanceOfOrganizationMetrics(value: object): value is OrganizationMetrics {
  return true;
}

export function OrganizationMetricsFromJSON(json: any): OrganizationMetrics {
  return OrganizationMetricsFromJSONTyped(json, false);
}

export function OrganizationMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMetrics {
  if (json == null) {
    return json;
  }
  return {
    organizationCount: json['organizationCount'] == null ? undefined : json['organizationCount'],
    userCount: json['userCount'] == null ? undefined : json['userCount']
  };
}

export function OrganizationMetricsToJSON(json: any): OrganizationMetrics {
  return OrganizationMetricsToJSONTyped(json, false);
}

export function OrganizationMetricsToJSONTyped(
  value?: OrganizationMetrics | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    organizationCount: value['organizationCount'],
    userCount: value['userCount']
  };
}
