/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationAreasInfo } from './OrganizationAreasInfo';
import {
  OrganizationAreasInfoFromJSON,
  OrganizationAreasInfoFromJSONTyped,
  OrganizationAreasInfoToJSON,
  OrganizationAreasInfoToJSONTyped
} from './OrganizationAreasInfo';
import type { OrganizationMetrics } from './OrganizationMetrics';
import {
  OrganizationMetricsFromJSON,
  OrganizationMetricsFromJSONTyped,
  OrganizationMetricsToJSON,
  OrganizationMetricsToJSONTyped
} from './OrganizationMetrics';

/**
 *
 * @export
 * @interface StatisticsData
 */
export interface StatisticsData {
  /**
   *
   * @type {number}
   * @memberof StatisticsData
   */
  neverLoggedInOrganizationCount?: number;
  /**
   *
   * @type {Array<OrganizationAreasInfo>}
   * @memberof StatisticsData
   */
  organizationAreas?: Array<OrganizationAreasInfo>;
  /**
   *
   * @type {Array<OrganizationMetrics>}
   * @memberof StatisticsData
   */
  organizationsMetrics?: Array<OrganizationMetrics>;
  /**
   *
   * @type {Array<OrganizationAreasInfo>}
   * @memberof StatisticsData
   */
  otherOrganizationAreas?: Array<OrganizationAreasInfo>;
}

/**
 * Check if a given object implements the StatisticsData interface.
 */
export function instanceOfStatisticsData(value: object): value is StatisticsData {
  return true;
}

export function StatisticsDataFromJSON(json: any): StatisticsData {
  return StatisticsDataFromJSONTyped(json, false);
}

export function StatisticsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsData {
  if (json == null) {
    return json;
  }
  return {
    neverLoggedInOrganizationCount:
      json['neverLoggedInOrganizationCount'] == null ? undefined : json['neverLoggedInOrganizationCount'],
    organizationAreas:
      json['organizationAreas'] == null
        ? undefined
        : (json['organizationAreas'] as Array<any>).map(OrganizationAreasInfoFromJSON),
    organizationsMetrics:
      json['organizationsMetrics'] == null
        ? undefined
        : (json['organizationsMetrics'] as Array<any>).map(OrganizationMetricsFromJSON),
    otherOrganizationAreas:
      json['otherOrganizationAreas'] == null
        ? undefined
        : (json['otherOrganizationAreas'] as Array<any>).map(OrganizationAreasInfoFromJSON)
  };
}

export function StatisticsDataToJSON(json: any): StatisticsData {
  return StatisticsDataToJSONTyped(json, false);
}

export function StatisticsDataToJSONTyped(value?: StatisticsData | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    neverLoggedInOrganizationCount: value['neverLoggedInOrganizationCount'],
    organizationAreas:
      value['organizationAreas'] == null
        ? undefined
        : (value['organizationAreas'] as Array<any>).map(OrganizationAreasInfoToJSON),
    organizationsMetrics:
      value['organizationsMetrics'] == null
        ? undefined
        : (value['organizationsMetrics'] as Array<any>).map(OrganizationMetricsToJSON),
    otherOrganizationAreas:
      value['otherOrganizationAreas'] == null
        ? undefined
        : (value['otherOrganizationAreas'] as Array<any>).map(OrganizationAreasInfoToJSON)
  };
}
