/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Message, MessageDescription } from '../models/index';
import { MessageFromJSON, MessageToJSON, MessageDescriptionFromJSON, MessageDescriptionToJSON } from '../models/index';

export interface CreateMessageUsingPOSTRequest {
  message: Message;
}

export interface DeleteMessagesUsingDELETERequest {
  requestBody: Array<string>;
}

export interface DownloadMessageUsingGETRequest {
  messagename: string;
}

export interface PublishMessageUsingPOSTRequest {
  messagename: string;
  body: string;
}

/**
 * MessagingControllerApi - interface
 *
 * @export
 * @interface MessagingControllerApiInterface
 */
export interface MessagingControllerApiInterface {
  /**
   * createMessage
   * @summary Creates a new message.
   * @param {Message} message
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  createMessageUsingPOSTRaw(
    requestParameters: CreateMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>>;

  /**
   * createMessage
   * Creates a new message.
   */
  createMessageUsingPOST(
    requestParameters: CreateMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean>;

  /**
   * deleteMessages
   * @summary Deletes the message.
   * @param {Array<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  deleteMessagesUsingDELETERaw(
    requestParameters: DeleteMessagesUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>>;

  /**
   * deleteMessages
   * Deletes the message.
   */
  deleteMessagesUsingDELETE(
    requestParameters: DeleteMessagesUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void>;

  /**
   * downloadMessage
   * @summary Gets the current messages for the user.
   * @param {string} messagename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  downloadMessageUsingGETRaw(
    requestParameters: DownloadMessageUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Message>>;

  /**
   * downloadMessage
   * Gets the current messages for the user.
   */
  downloadMessageUsingGET(
    requestParameters: DownloadMessageUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Message>;

  /**
   * hasWriteAccess
   * @summary Checks whether the user has write access to messages
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  hasWriteAccessUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>>;

  /**
   * hasWriteAccess
   * Checks whether the user has write access to messages
   */
  hasWriteAccessUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

  /**
   * listMessages
   * @summary Gets the current messages for the user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  listMessagesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MessageDescription>>>;

  /**
   * listMessages
   * Gets the current messages for the user.
   */
  listMessagesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDescription>>;

  /**
   * publishMessage
   * @summary Publishes a message.
   * @param {string} messagename
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagingControllerApiInterface
   */
  publishMessageUsingPOSTRaw(
    requestParameters: PublishMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>>;

  /**
   * publishMessage
   * Publishes a message.
   */
  publishMessageUsingPOST(
    requestParameters: PublishMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean>;
}

/**
 *
 */
export class MessagingControllerApi extends runtime.BaseAPI implements MessagingControllerApiInterface {
  /**
   * createMessage
   * Creates a new message.
   */
  async createMessageUsingPOSTRaw(
    requestParameters: CreateMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters['message'] == null) {
      throw new runtime.RequiredError(
        'message',
        'Required parameter "message" was null or undefined when calling createMessageUsingPOST().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/create`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MessageToJSON(requestParameters['message'])
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * createMessage
   * Creates a new message.
   */
  async createMessageUsingPOST(
    requestParameters: CreateMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.createMessageUsingPOSTRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * deleteMessages
   * Deletes the message.
   */
  async deleteMessagesUsingDELETERaw(
    requestParameters: DeleteMessagesUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['requestBody'] == null) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter "requestBody" was null or undefined when calling deleteMessagesUsingDELETE().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/delete`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['requestBody']
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * deleteMessages
   * Deletes the message.
   */
  async deleteMessagesUsingDELETE(
    requestParameters: DeleteMessagesUsingDELETERequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteMessagesUsingDELETERaw(requestParameters, initOverrides);
  }

  /**
   * downloadMessage
   * Gets the current messages for the user.
   */
  async downloadMessageUsingGETRaw(
    requestParameters: DownloadMessageUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Message>> {
    if (requestParameters['messagename'] == null) {
      throw new runtime.RequiredError(
        'messagename',
        'Required parameter "messagename" was null or undefined when calling downloadMessageUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/message/{messagename}`.replace(
          `{${'messagename'}}`,
          encodeURIComponent(String(requestParameters['messagename']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MessageFromJSON(jsonValue));
  }

  /**
   * downloadMessage
   * Gets the current messages for the user.
   */
  async downloadMessageUsingGET(
    requestParameters: DownloadMessageUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Message> {
    const response = await this.downloadMessageUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * hasWriteAccess
   * Checks whether the user has write access to messages
   */
  async hasWriteAccessUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/writeaccess`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * hasWriteAccess
   * Checks whether the user has write access to messages
   */
  async hasWriteAccessUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
    const response = await this.hasWriteAccessUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * listMessages
   * Gets the current messages for the user.
   */
  async listMessagesUsingGETRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MessageDescription>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/list`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MessageDescriptionFromJSON));
  }

  /**
   * listMessages
   * Gets the current messages for the user.
   */
  async listMessagesUsingGET(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MessageDescription>> {
    const response = await this.listMessagesUsingGETRaw(initOverrides);
    return await response.value();
  }

  /**
   * publishMessage
   * Publishes a message.
   */
  async publishMessageUsingPOSTRaw(
    requestParameters: PublishMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters['messagename'] == null) {
      throw new runtime.RequiredError(
        'messagename',
        'Required parameter "messagename" was null or undefined when calling publishMessageUsingPOST().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling publishMessageUsingPOST().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/messaging/publish/{messagename}`.replace(
          `{${'messagename'}}`,
          encodeURIComponent(String(requestParameters['messagename']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<boolean>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * publishMessage
   * Publishes a message.
   */
  async publishMessageUsingPOST(
    requestParameters: PublishMessageUsingPOSTRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.publishMessageUsingPOSTRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
