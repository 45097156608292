/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LogEvent
 */
export interface LogEvent {
  /**
   *
   * @type {string}
   * @memberof LogEvent
   */
  event: string;
  /**
   *
   * @type {string}
   * @memberof LogEvent
   */
  level: string;
  /**
   *
   * @type {string}
   * @memberof LogEvent
   */
  payload?: string;
  /**
   *
   * @type {string}
   * @memberof LogEvent
   */
  timestamp: string;
}

/**
 * Check if a given object implements the LogEvent interface.
 */
export function instanceOfLogEvent(value: object): value is LogEvent {
  if (!('event' in value) || value['event'] === undefined) return false;
  if (!('level' in value) || value['level'] === undefined) return false;
  if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
  return true;
}

export function LogEventFromJSON(json: any): LogEvent {
  return LogEventFromJSONTyped(json, false);
}

export function LogEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogEvent {
  if (json == null) {
    return json;
  }
  return {
    event: json['event'],
    level: json['level'],
    payload: json['payload'] == null ? undefined : json['payload'],
    timestamp: json['timestamp']
  };
}

export function LogEventToJSON(json: any): LogEvent {
  return LogEventToJSONTyped(json, false);
}

export function LogEventToJSONTyped(value?: LogEvent | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    event: value['event'],
    level: value['level'],
    payload: value['payload'],
    timestamp: value['timestamp']
  };
}
