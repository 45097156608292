/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Colleague
 */
export interface Colleague {
  /**
   *
   * @type {string}
   * @memberof Colleague
   */
  displayName?: string;
  /**
   *
   * @type {boolean}
   * @memberof Colleague
   */
  isCompanyAdmin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Colleague
   */
  isLiebherrEmployee?: boolean;
  /**
   *
   * @type {string}
   * @memberof Colleague
   */
  mail?: string;
  /**
   *
   * @type {string}
   * @memberof Colleague
   */
  mobile?: string;
  /**
   *
   * @type {boolean}
   * @memberof Colleague
   */
  mobileValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof Colleague
   */
  telephoneNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof Colleague
   */
  telephoneNumberValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof Colleague
   */
  userPrincipalName?: string;
}

/**
 * Check if a given object implements the Colleague interface.
 */
export function instanceOfColleague(value: object): value is Colleague {
  return true;
}

export function ColleagueFromJSON(json: any): Colleague {
  return ColleagueFromJSONTyped(json, false);
}

export function ColleagueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Colleague {
  if (json == null) {
    return json;
  }
  return {
    displayName: json['displayName'] == null ? undefined : json['displayName'],
    isCompanyAdmin: json['isCompanyAdmin'] == null ? undefined : json['isCompanyAdmin'],
    isLiebherrEmployee: json['isLiebherrEmployee'] == null ? undefined : json['isLiebherrEmployee'],
    mail: json['mail'] == null ? undefined : json['mail'],
    mobile: json['mobile'] == null ? undefined : json['mobile'],
    mobileValid: json['mobileValid'] == null ? undefined : json['mobileValid'],
    telephoneNumber: json['telephoneNumber'] == null ? undefined : json['telephoneNumber'],
    telephoneNumberValid: json['telephoneNumberValid'] == null ? undefined : json['telephoneNumberValid'],
    userPrincipalName: json['userPrincipalName'] == null ? undefined : json['userPrincipalName']
  };
}

export function ColleagueToJSON(json: any): Colleague {
  return ColleagueToJSONTyped(json, false);
}

export function ColleagueToJSONTyped(value?: Colleague | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    displayName: value['displayName'],
    isCompanyAdmin: value['isCompanyAdmin'],
    isLiebherrEmployee: value['isLiebherrEmployee'],
    mail: value['mail'],
    mobile: value['mobile'],
    mobileValid: value['mobileValid'],
    telephoneNumber: value['telephoneNumber'],
    telephoneNumberValid: value['telephoneNumberValid'],
    userPrincipalName: value['userPrincipalName']
  };
}
