/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  companyId?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  firstName?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  internalUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  mail?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  mobile?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  mobileValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  telephoneNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponse
   */
  telephoneNumberValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  userPrincipalName?: string;
}

/**
 * Check if a given object implements the UserResponse interface.
 */
export function instanceOfUserResponse(value: object): value is UserResponse {
  return true;
}

export function UserResponseFromJSON(json: any): UserResponse {
  return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
  if (json == null) {
    return json;
  }
  return {
    companyId: json['companyId'] == null ? undefined : json['companyId'],
    country: json['country'] == null ? undefined : json['country'],
    firstName: json['firstName'] == null ? undefined : json['firstName'],
    internalUser: json['internalUser'] == null ? undefined : json['internalUser'],
    lastName: json['lastName'] == null ? undefined : json['lastName'],
    mail: json['mail'] == null ? undefined : json['mail'],
    mobile: json['mobile'] == null ? undefined : json['mobile'],
    mobileValid: json['mobileValid'] == null ? undefined : json['mobileValid'],
    telephoneNumber: json['telephoneNumber'] == null ? undefined : json['telephoneNumber'],
    telephoneNumberValid: json['telephoneNumberValid'] == null ? undefined : json['telephoneNumberValid'],
    userPrincipalName: json['userPrincipalName'] == null ? undefined : json['userPrincipalName']
  };
}

export function UserResponseToJSON(json: any): UserResponse {
  return UserResponseToJSONTyped(json, false);
}

export function UserResponseToJSONTyped(value?: UserResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    companyId: value['companyId'],
    country: value['country'],
    firstName: value['firstName'],
    internalUser: value['internalUser'],
    lastName: value['lastName'],
    mail: value['mail'],
    mobile: value['mobile'],
    mobileValid: value['mobileValid'],
    telephoneNumber: value['telephoneNumber'],
    telephoneNumberValid: value['telephoneNumberValid'],
    userPrincipalName: value['userPrincipalName']
  };
}
