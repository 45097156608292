/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApplianceResponse
 */
export interface ApplianceResponse {
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  applianceType?: ApplianceResponseApplianceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  compartmentType?: ApplianceResponseCompartmentTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof ApplianceResponse
   */
  connected?: boolean;
  /**
   *
   * @type {Date}
   * @memberof ApplianceResponse
   */
  connectionStateSince?: Date;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  countryIsoCode?: string;
  /**
   *
   * @type {Date}
   * @memberof ApplianceResponse
   */
  created?: Date;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  department?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  floor?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  inventoryNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  measureSiteType?: ApplianceResponseMeasureSiteTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ApplianceResponse
   */
  modbusAddress?: number;
  /**
   *
   * @type {number}
   * @memberof ApplianceResponse
   */
  modbusMappingNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  networkType?: ApplianceResponseNetworkTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ApplianceResponse
   */
  numberOfZones?: number;
  /**
   *
   * @type {Date}
   * @memberof ApplianceResponse
   */
  orderDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  roomNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  smartModuleSoftwareVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceResponse
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const ApplianceResponseApplianceTypeEnum = {
  SINGLE_COMPARTMENT: 'SINGLE_COMPARTMENT',
  MULTI_COMPARTMENT: 'MULTI_COMPARTMENT'
} as const;
export type ApplianceResponseApplianceTypeEnum =
  (typeof ApplianceResponseApplianceTypeEnum)[keyof typeof ApplianceResponseApplianceTypeEnum];

/**
 * @export
 */
export const ApplianceResponseCompartmentTypeEnum = {
  COOLER: 'COOLER',
  FREEZER: 'FREEZER'
} as const;
export type ApplianceResponseCompartmentTypeEnum =
  (typeof ApplianceResponseCompartmentTypeEnum)[keyof typeof ApplianceResponseCompartmentTypeEnum];

/**
 * @export
 */
export const ApplianceResponseMeasureSiteTypeEnum = {
  ROOM: 'ROOM',
  MODBUS_FRIDGE: 'MODBUS_FRIDGE',
  NOT_CONNECTABLE_FRIDGE: 'NOT_CONNECTABLE_FRIDGE',
  THIRD_PARTY_FRIDGE: 'THIRD_PARTY_FRIDGE',
  BINDER_FRIDGE: 'BINDER_FRIDGE',
  SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE'
} as const;
export type ApplianceResponseMeasureSiteTypeEnum =
  (typeof ApplianceResponseMeasureSiteTypeEnum)[keyof typeof ApplianceResponseMeasureSiteTypeEnum];

/**
 * @export
 */
export const ApplianceResponseNetworkTypeEnum = {
  LAN: 'LAN',
  WIFI: 'WIFI'
} as const;
export type ApplianceResponseNetworkTypeEnum =
  (typeof ApplianceResponseNetworkTypeEnum)[keyof typeof ApplianceResponseNetworkTypeEnum];

/**
 * Check if a given object implements the ApplianceResponse interface.
 */
export function instanceOfApplianceResponse(value: object): value is ApplianceResponse {
  return true;
}

export function ApplianceResponseFromJSON(json: any): ApplianceResponse {
  return ApplianceResponseFromJSONTyped(json, false);
}

export function ApplianceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceResponse {
  if (json == null) {
    return json;
  }
  return {
    applianceType: json['applianceType'] == null ? undefined : json['applianceType'],
    articleNumber: json['articleNumber'] == null ? undefined : json['articleNumber'],
    building: json['building'] == null ? undefined : json['building'],
    compartmentType: json['compartmentType'] == null ? undefined : json['compartmentType'],
    connected: json['connected'] == null ? undefined : json['connected'],
    connectionStateSince: json['connectionStateSince'] == null ? undefined : new Date(json['connectionStateSince']),
    countryIsoCode: json['countryIsoCode'] == null ? undefined : json['countryIsoCode'],
    created: json['created'] == null ? undefined : new Date(json['created']),
    department: json['department'] == null ? undefined : json['department'],
    floor: json['floor'] == null ? undefined : json['floor'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    inventoryNumber: json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
    ipAddress: json['ipAddress'] == null ? undefined : json['ipAddress'],
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    measureSiteType: json['measureSiteType'] == null ? undefined : json['measureSiteType'],
    modbusAddress: json['modbusAddress'] == null ? undefined : json['modbusAddress'],
    modbusMappingNumber: json['modbusMappingNumber'] == null ? undefined : json['modbusMappingNumber'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    networkType: json['networkType'] == null ? undefined : json['networkType'],
    numberOfZones: json['numberOfZones'] == null ? undefined : json['numberOfZones'],
    orderDate: json['orderDate'] == null ? undefined : new Date(json['orderDate']),
    organizationId: json['organizationId'] == null ? undefined : json['organizationId'],
    roomNumber: json['roomNumber'] == null ? undefined : json['roomNumber'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    smartModuleSoftwareVersion:
      json['smartModuleSoftwareVersion'] == null ? undefined : json['smartModuleSoftwareVersion'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function ApplianceResponseToJSON(json: any): ApplianceResponse {
  return ApplianceResponseToJSONTyped(json, false);
}

export function ApplianceResponseToJSONTyped(
  value?: ApplianceResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    applianceType: value['applianceType'],
    articleNumber: value['articleNumber'],
    building: value['building'],
    compartmentType: value['compartmentType'],
    connected: value['connected'],
    connectionStateSince:
      value['connectionStateSince'] == null ? undefined : value['connectionStateSince'].toISOString(),
    countryIsoCode: value['countryIsoCode'],
    created: value['created'] == null ? undefined : value['created'].toISOString(),
    department: value['department'],
    floor: value['floor'],
    gatewayId: value['gatewayId'],
    inventoryNumber: value['inventoryNumber'],
    ipAddress: value['ipAddress'],
    macAddress: value['macAddress'],
    measureSiteType: value['measureSiteType'],
    modbusAddress: value['modbusAddress'],
    modbusMappingNumber: value['modbusMappingNumber'],
    model: value['model'],
    name: value['name'],
    networkType: value['networkType'],
    numberOfZones: value['numberOfZones'],
    orderDate: value['orderDate'] == null ? undefined : value['orderDate'].toISOString(),
    organizationId: value['organizationId'],
    roomNumber: value['roomNumber'],
    serialNumber: value['serialNumber'],
    smartModuleSoftwareVersion: value['smartModuleSoftwareVersion'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
