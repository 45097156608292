/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GatewayMeasureSite } from './GatewayMeasureSite';
import {
  GatewayMeasureSiteFromJSON,
  GatewayMeasureSiteFromJSONTyped,
  GatewayMeasureSiteToJSON,
  GatewayMeasureSiteToJSONTyped
} from './GatewayMeasureSite';

/**
 *
 * @export
 * @interface GatewayMeasureSiteResponse
 */
export interface GatewayMeasureSiteResponse {
  /**
   *
   * @type {Array<GatewayMeasureSite>}
   * @memberof GatewayMeasureSiteResponse
   */
  appliances?: Array<GatewayMeasureSite>;
}

/**
 * Check if a given object implements the GatewayMeasureSiteResponse interface.
 */
export function instanceOfGatewayMeasureSiteResponse(value: object): value is GatewayMeasureSiteResponse {
  return true;
}

export function GatewayMeasureSiteResponseFromJSON(json: any): GatewayMeasureSiteResponse {
  return GatewayMeasureSiteResponseFromJSONTyped(json, false);
}

export function GatewayMeasureSiteResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GatewayMeasureSiteResponse {
  if (json == null) {
    return json;
  }
  return {
    appliances:
      json['appliances'] == null ? undefined : (json['appliances'] as Array<any>).map(GatewayMeasureSiteFromJSON)
  };
}

export function GatewayMeasureSiteResponseToJSON(json: any): GatewayMeasureSiteResponse {
  return GatewayMeasureSiteResponseToJSONTyped(json, false);
}

export function GatewayMeasureSiteResponseToJSONTyped(
  value?: GatewayMeasureSiteResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    appliances:
      value['appliances'] == null ? undefined : (value['appliances'] as Array<any>).map(GatewayMeasureSiteToJSON)
  };
}
