/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MergedAlarm
 */
export interface MergedAlarm {
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  alarmId?: string;
  /**
   *
   * @type {Date}
   * @memberof MergedAlarm
   */
  alarmTime?: Date;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  alarmType?: string;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  commentCount?: number;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  connectedDeviceName?: string;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  deviceName?: string;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  duration?: number;
  /**
   *
   * @type {Date}
   * @memberof MergedAlarm
   */
  endTime?: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof MergedAlarm
   */
  errorCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  maxAlarmTemperature?: number;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  maxAlarmTemperatureThreshold?: number;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  minAlarmTemperature?: number;
  /**
   *
   * @type {number}
   * @memberof MergedAlarm
   */
  minAlarmTemperatureThreshold?: number;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  model?: string;
  /**
   *
   * @type {boolean}
   * @memberof MergedAlarm
   */
  readstate?: boolean;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  source?: MergedAlarmSourceEnum;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  state?: MergedAlarmStateEnum;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  task?: string;
  /**
   *
   * @type {string}
   * @memberof MergedAlarm
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const MergedAlarmSourceEnum = {
  GATEWAY: 'GATEWAY',
  APPLIANCE: 'APPLIANCE',
  SMART_MODULE: 'SMART_MODULE'
} as const;
export type MergedAlarmSourceEnum = (typeof MergedAlarmSourceEnum)[keyof typeof MergedAlarmSourceEnum];

/**
 * @export
 */
export const MergedAlarmStateEnum = {
  ON: 'On',
  OFF: 'Off'
} as const;
export type MergedAlarmStateEnum = (typeof MergedAlarmStateEnum)[keyof typeof MergedAlarmStateEnum];

/**
 * Check if a given object implements the MergedAlarm interface.
 */
export function instanceOfMergedAlarm(value: object): value is MergedAlarm {
  return true;
}

export function MergedAlarmFromJSON(json: any): MergedAlarm {
  return MergedAlarmFromJSONTyped(json, false);
}

export function MergedAlarmFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedAlarm {
  if (json == null) {
    return json;
  }
  return {
    alarmId: json['alarmId'] == null ? undefined : json['alarmId'],
    alarmTime: json['alarmTime'] == null ? undefined : new Date(json['alarmTime']),
    alarmType: json['alarmType'] == null ? undefined : json['alarmType'],
    commentCount: json['commentCount'] == null ? undefined : json['commentCount'],
    connectedDeviceName: json['connectedDeviceName'] == null ? undefined : json['connectedDeviceName'],
    deviceName: json['deviceName'] == null ? undefined : json['deviceName'],
    duration: json['duration'] == null ? undefined : json['duration'],
    endTime: json['endTime'] == null ? undefined : new Date(json['endTime']),
    errorCodes: json['errorCodes'] == null ? undefined : json['errorCodes'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    id: json['id'] == null ? undefined : json['id'],
    maxAlarmTemperature: json['maxAlarmTemperature'] == null ? undefined : json['maxAlarmTemperature'],
    maxAlarmTemperatureThreshold:
      json['maxAlarmTemperatureThreshold'] == null ? undefined : json['maxAlarmTemperatureThreshold'],
    minAlarmTemperature: json['minAlarmTemperature'] == null ? undefined : json['minAlarmTemperature'],
    minAlarmTemperatureThreshold:
      json['minAlarmTemperatureThreshold'] == null ? undefined : json['minAlarmTemperatureThreshold'],
    model: json['model'] == null ? undefined : json['model'],
    readstate: json['readstate'] == null ? undefined : json['readstate'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    source: json['source'] == null ? undefined : json['source'],
    state: json['state'] == null ? undefined : json['state'],
    task: json['task'] == null ? undefined : json['task'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function MergedAlarmToJSON(json: any): MergedAlarm {
  return MergedAlarmToJSONTyped(json, false);
}

export function MergedAlarmToJSONTyped(value?: MergedAlarm | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    alarmId: value['alarmId'],
    alarmTime: value['alarmTime'] == null ? undefined : value['alarmTime'].toISOString(),
    alarmType: value['alarmType'],
    commentCount: value['commentCount'],
    connectedDeviceName: value['connectedDeviceName'],
    deviceName: value['deviceName'],
    duration: value['duration'],
    endTime: value['endTime'] == null ? undefined : value['endTime'].toISOString(),
    errorCodes: value['errorCodes'],
    gatewayId: value['gatewayId'],
    id: value['id'],
    maxAlarmTemperature: value['maxAlarmTemperature'],
    maxAlarmTemperatureThreshold: value['maxAlarmTemperatureThreshold'],
    minAlarmTemperature: value['minAlarmTemperature'],
    minAlarmTemperatureThreshold: value['minAlarmTemperatureThreshold'],
    model: value['model'],
    readstate: value['readstate'],
    serialNumber: value['serialNumber'],
    source: value['source'],
    state: value['state'],
    task: value['task'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
