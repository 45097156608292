/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MeasureSite
 */
export interface MeasureSite {
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  department?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  inventoryNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  level?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  measureSiteType: MeasureSiteMeasureSiteTypeEnum;
  /**
   *
   * @type {number}
   * @memberof MeasureSite
   */
  modbusAddress?: number;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof MeasureSite
   */
  orderDate?: Date;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  roomNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  thingId?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSite
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const MeasureSiteMeasureSiteTypeEnum = {
  ROOM: 'ROOM',
  MODBUS_FRIDGE: 'MODBUS_FRIDGE',
  NOT_CONNECTABLE_FRIDGE: 'NOT_CONNECTABLE_FRIDGE',
  THIRD_PARTY_FRIDGE: 'THIRD_PARTY_FRIDGE',
  BINDER_FRIDGE: 'BINDER_FRIDGE',
  SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE'
} as const;
export type MeasureSiteMeasureSiteTypeEnum =
  (typeof MeasureSiteMeasureSiteTypeEnum)[keyof typeof MeasureSiteMeasureSiteTypeEnum];

/**
 * Check if a given object implements the MeasureSite interface.
 */
export function instanceOfMeasureSite(value: object): value is MeasureSite {
  if (!('measureSiteType' in value) || value['measureSiteType'] === undefined) return false;
  return true;
}

export function MeasureSiteFromJSON(json: any): MeasureSite {
  return MeasureSiteFromJSONTyped(json, false);
}

export function MeasureSiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasureSite {
  if (json == null) {
    return json;
  }
  return {
    articleNumber: json['articleNumber'] == null ? undefined : json['articleNumber'],
    building: json['building'] == null ? undefined : json['building'],
    comment: json['comment'] == null ? undefined : json['comment'],
    department: json['department'] == null ? undefined : json['department'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    inventoryNumber: json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
    level: json['level'] == null ? undefined : json['level'],
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    manufacturer: json['manufacturer'] == null ? undefined : json['manufacturer'],
    measureSiteType: json['measureSiteType'],
    modbusAddress: json['modbusAddress'] == null ? undefined : json['modbusAddress'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    orderDate: json['orderDate'] == null ? undefined : new Date(json['orderDate']),
    roomNumber: json['roomNumber'] == null ? undefined : json['roomNumber'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    thingId: json['thingId'] == null ? undefined : json['thingId'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function MeasureSiteToJSON(json: any): MeasureSite {
  return MeasureSiteToJSONTyped(json, false);
}

export function MeasureSiteToJSONTyped(value?: MeasureSite | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    articleNumber: value['articleNumber'],
    building: value['building'],
    comment: value['comment'],
    department: value['department'],
    gatewayId: value['gatewayId'],
    inventoryNumber: value['inventoryNumber'],
    level: value['level'],
    macAddress: value['macAddress'],
    manufacturer: value['manufacturer'],
    measureSiteType: value['measureSiteType'],
    modbusAddress: value['modbusAddress'],
    model: value['model'],
    name: value['name'],
    orderDate: value['orderDate'] == null ? undefined : value['orderDate'].toISOString(),
    roomNumber: value['roomNumber'],
    serialNumber: value['serialNumber'],
    thingId: value['thingId'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
