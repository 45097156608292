/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationAreasInfo
 */
export interface OrganizationAreasInfo {
  /**
   *
   * @type {string}
   * @memberof OrganizationAreasInfo
   */
  area?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationAreasInfo
   */
  count?: number;
}

/**
 * Check if a given object implements the OrganizationAreasInfo interface.
 */
export function instanceOfOrganizationAreasInfo(value: object): value is OrganizationAreasInfo {
  return true;
}

export function OrganizationAreasInfoFromJSON(json: any): OrganizationAreasInfo {
  return OrganizationAreasInfoFromJSONTyped(json, false);
}

export function OrganizationAreasInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationAreasInfo {
  if (json == null) {
    return json;
  }
  return {
    area: json['area'] == null ? undefined : json['area'],
    count: json['count'] == null ? undefined : json['count']
  };
}

export function OrganizationAreasInfoToJSON(json: any): OrganizationAreasInfo {
  return OrganizationAreasInfoToJSONTyped(json, false);
}

export function OrganizationAreasInfoToJSONTyped(
  value?: OrganizationAreasInfo | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    area: value['area'],
    count: value['count']
  };
}
