/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  locality?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyResponse
   */
  street2?: string;
}

/**
 * Check if a given object implements the CompanyResponse interface.
 */
export function instanceOfCompanyResponse(value: object): value is CompanyResponse {
  return true;
}

export function CompanyResponseFromJSON(json: any): CompanyResponse {
  return CompanyResponseFromJSONTyped(json, false);
}

export function CompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyResponse {
  if (json == null) {
    return json;
  }
  return {
    companyName: json['companyName'] == null ? undefined : json['companyName'],
    country: json['country'] == null ? undefined : json['country'],
    locality: json['locality'] == null ? undefined : json['locality'],
    postalCode: json['postalCode'] == null ? undefined : json['postalCode'],
    street: json['street'] == null ? undefined : json['street'],
    street2: json['street2'] == null ? undefined : json['street2']
  };
}

export function CompanyResponseToJSON(json: any): CompanyResponse {
  return CompanyResponseToJSONTyped(json, false);
}

export function CompanyResponseToJSONTyped(value?: CompanyResponse | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    companyName: value['companyName'],
    country: value['country'],
    locality: value['locality'],
    postalCode: value['postalCode'],
    street: value['street'],
    street2: value['street2']
  };
}
