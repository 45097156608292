/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { ExtendedGatewayState } from '../models/index';
import { ExtendedGatewayStateFromJSON, ExtendedGatewayStateToJSON } from '../models/index';

export interface GetBsdbByGatewayIdUsingGETRequest {
  gatewayId: string;
}

export interface GetBsdbBySerialNumberUsingGETRequest {
  serialNumber: string;
}

/**
 * BsdbControllerApi - interface
 *
 * @export
 * @interface BsdbControllerApiInterface
 */
export interface BsdbControllerApiInterface {
  /**
   * getBsdbByGatewayId
   * @summary Gets state of bsdb by gatewayId
   * @param {string} gatewayId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BsdbControllerApiInterface
   */
  getBsdbByGatewayIdUsingGETRaw(
    requestParameters: GetBsdbByGatewayIdUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendedGatewayState>>;

  /**
   * getBsdbByGatewayId
   * Gets state of bsdb by gatewayId
   */
  getBsdbByGatewayIdUsingGET(
    requestParameters: GetBsdbByGatewayIdUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendedGatewayState>;

  /**
   * getBsdbBySerialNumber
   * @summary Gets state of bsdb by serialNumber
   * @param {string} serialNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BsdbControllerApiInterface
   */
  getBsdbBySerialNumberUsingGETRaw(
    requestParameters: GetBsdbBySerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendedGatewayState>>;

  /**
   * getBsdbBySerialNumber
   * Gets state of bsdb by serialNumber
   */
  getBsdbBySerialNumberUsingGET(
    requestParameters: GetBsdbBySerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendedGatewayState>;
}

/**
 *
 */
export class BsdbControllerApi extends runtime.BaseAPI implements BsdbControllerApiInterface {
  /**
   * getBsdbByGatewayId
   * Gets state of bsdb by gatewayId
   */
  async getBsdbByGatewayIdUsingGETRaw(
    requestParameters: GetBsdbByGatewayIdUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendedGatewayState>> {
    if (requestParameters['gatewayId'] == null) {
      throw new runtime.RequiredError(
        'gatewayId',
        'Required parameter "gatewayId" was null or undefined when calling getBsdbByGatewayIdUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/bsdb/{gatewayId}/state`.replace(
          `{${'gatewayId'}}`,
          encodeURIComponent(String(requestParameters['gatewayId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ExtendedGatewayStateFromJSON(jsonValue));
  }

  /**
   * getBsdbByGatewayId
   * Gets state of bsdb by gatewayId
   */
  async getBsdbByGatewayIdUsingGET(
    requestParameters: GetBsdbByGatewayIdUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendedGatewayState> {
    const response = await this.getBsdbByGatewayIdUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * getBsdbBySerialNumber
   * Gets state of bsdb by serialNumber
   */
  async getBsdbBySerialNumberUsingGETRaw(
    requestParameters: GetBsdbBySerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendedGatewayState>> {
    if (requestParameters['serialNumber'] == null) {
      throw new runtime.RequiredError(
        'serialNumber',
        'Required parameter "serialNumber" was null or undefined when calling getBsdbBySerialNumberUsingGET().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = await this.configuration.apiKey('Authorization'); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/bsdb/serialNumber/{serialNumber}/state`.replace(
          `{${'serialNumber'}}`,
          encodeURIComponent(String(requestParameters['serialNumber']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ExtendedGatewayStateFromJSON(jsonValue));
  }

  /**
   * getBsdbBySerialNumber
   * Gets state of bsdb by serialNumber
   */
  async getBsdbBySerialNumberUsingGET(
    requestParameters: GetBsdbBySerialNumberUsingGETRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendedGatewayState> {
    const response = await this.getBsdbBySerialNumberUsingGETRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
