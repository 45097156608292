/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SupportInfoContact
 */
export interface SupportInfoContact {
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact1?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact2?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact3?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact4?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact5?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoContact
   */
  contact6?: string;
}

/**
 * Check if a given object implements the SupportInfoContact interface.
 */
export function instanceOfSupportInfoContact(value: object): value is SupportInfoContact {
  return true;
}

export function SupportInfoContactFromJSON(json: any): SupportInfoContact {
  return SupportInfoContactFromJSONTyped(json, false);
}

export function SupportInfoContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportInfoContact {
  if (json == null) {
    return json;
  }
  return {
    contact1: json['contact1'] == null ? undefined : json['contact1'],
    contact2: json['contact2'] == null ? undefined : json['contact2'],
    contact3: json['contact3'] == null ? undefined : json['contact3'],
    contact4: json['contact4'] == null ? undefined : json['contact4'],
    contact5: json['contact5'] == null ? undefined : json['contact5'],
    contact6: json['contact6'] == null ? undefined : json['contact6']
  };
}

export function SupportInfoContactToJSON(json: any): SupportInfoContact {
  return SupportInfoContactToJSONTyped(json, false);
}

export function SupportInfoContactToJSONTyped(
  value?: SupportInfoContact | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    contact1: value['contact1'],
    contact2: value['contact2'],
    contact3: value['contact3'],
    contact4: value['contact4'],
    contact5: value['contact5'],
    contact6: value['contact6']
  };
}
