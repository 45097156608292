/* tslint:disable */
/* eslint-disable */
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleZoneTemperature
 */
export interface SmartModuleZoneTemperature {
  /**
   *
   * @type {number}
   * @memberof SmartModuleZoneTemperature
   */
  displayTemperatureCelsius?: number;
  /**
   *
   * @type {number}
   * @memberof SmartModuleZoneTemperature
   */
  displayTemperatureFahrenheit?: number;
  /**
   *
   * @type {string}
   * @memberof SmartModuleZoneTemperature
   */
  temperatureUnit?: string;
}

/**
 * Check if a given object implements the SmartModuleZoneTemperature interface.
 */
export function instanceOfSmartModuleZoneTemperature(value: object): value is SmartModuleZoneTemperature {
  return true;
}

export function SmartModuleZoneTemperatureFromJSON(json: any): SmartModuleZoneTemperature {
  return SmartModuleZoneTemperatureFromJSONTyped(json, false);
}

export function SmartModuleZoneTemperatureFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartModuleZoneTemperature {
  if (json == null) {
    return json;
  }
  return {
    displayTemperatureCelsius:
      json['displayTemperatureCelsius'] == null ? undefined : json['displayTemperatureCelsius'],
    displayTemperatureFahrenheit:
      json['displayTemperatureFahrenheit'] == null ? undefined : json['displayTemperatureFahrenheit'],
    temperatureUnit: json['temperatureUnit'] == null ? undefined : json['temperatureUnit']
  };
}

export function SmartModuleZoneTemperatureToJSON(json: any): SmartModuleZoneTemperature {
  return SmartModuleZoneTemperatureToJSONTyped(json, false);
}

export function SmartModuleZoneTemperatureToJSONTyped(
  value?: SmartModuleZoneTemperature | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    displayTemperatureCelsius: value['displayTemperatureCelsius'],
    displayTemperatureFahrenheit: value['displayTemperatureFahrenheit'],
    temperatureUnit: value['temperatureUnit']
  };
}
